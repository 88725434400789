<template>
  <div>
    <button class="button hollow large" id="show-modal" @click="onShowModal">Select a Metric</button>
    <metrics-modal
      :showModal="showModal"
      :categoriesOptions="categoriesOptions"
      :empty="!Object.keys(categoriesOptions).length"
      @close-modal="showModal = false"
      @metric-selected="onSelectMetric"
    ></metrics-modal>
  </div>
</template>

<script>
import { fetchMetrics, fetchCategories } from "../api/";
import { getMetricOptions, filterOptions } from "../utils";
import metricsModal from "./metricsModal";

export default {
  data() {
    return {
      loading: true,
      categoriesOptions: [],
      selectedMetric: null,
      showModal: false,
    }
  },
  computed: {
    metricOptions: function () {
      return getMetricOptions(this.categoriesOptions)
    },
  },
  methods: {
    filterMetricOptions(option, label, search) { return filterOptions(option, label, search) },
    onShowModal() {
      this.showModal = true;
    },
    onSelectMetric(metricIdn) {
      this.showModal = false;
      window.location = `/metrics/?metric=${metricIdn}`;
    }
  },
  created() {
    Promise.all([fetchMetrics({}), fetchCategories()]).then(
      ([metrics, categoies]) => {
        this.categoriesOptions = categoies.map((category) => {
          return {
            ...category,
            subcategories: category.subcategories.map((subcategory) => {
            return {
                ...subcategory,
                metrics: metrics.filter((m) => m.subcategory == subcategory.id),
              }
            }),
          };
        });
        this.loading = false;
      }
    );
  },
  components: {
    "metrics-modal": metricsModal,
  },
}
</script>
