<template>
  <div>
    <modal v-if="showModal" @close-modal="$emit('close-modal')" id="metricsModal">
      <template v-slot:header>
        <div class="modal-header bg-sky-blue-20">
          <h2 class="modal-heading mb-2">CHOOSE A METRIC</h2>

          <div class="field small center">
            <label>FILTER BY CATEGORY</label>
            <v-select
              :options="categoriesOptions"
              :reduce="(option) => option.id"
              :value="categoryId"
              @input="selectCategory"
            ></v-select>
          </div>
        </div>
      </template>

      <template v-slot:body="{ close }">
        <div class="modal-body">
          <ul class="metrics-dialog-groups" :class="{ 'filtered': !!categoryId }">
            <li
              v-for="category in categories"
              :key="category.id"
              class="metrics-dialog-group"
            >
              <h5 class="metrics-dialog-category">{{ category.label }}</h5>
              <ul class="metrics-dialog-sub-groups" :class="{ 'filtered': !!categoryId }">
                <li
                  v-for="subcategory in category.subcategories"
                  :key="subcategory.id"
                   class="metrics-dialog-sub-group"
                >
                  <h6 class="metrics-dialog-subcategory">{{ subcategory.label }}</h6>
                  <button
                    v-for="metric in subcategory.metrics"
                    :key="metric.idn"
                    class="metrics-dialog-metric"
                    :class="{ disabled: !metric.available_for_city }"
                    :disabled="!metric.available_for_city"
                    @click="
                      metric.available_for_city && close();
                      modalSelectIndex != undefined ?
                        $emit('metric-selected', modalSelectIndex, metric.idn) :
                        $emit('metric-selected', metric.idn);
                      "
                    >
                      {{ metric.label }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
          <p v-if="empty" class="text-center is-gray">
            <strong>Choose a primary city to load available metrics.</strong>
          </p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import modal from "./modal";

export default {
  props: ["showModal", "categoriesOptions", "modalSelectIndex", "empty"],
  components: { modal },
  data() {
    return { categoryId: null };
  },
  computed: {
    categories() {
      return this.categoriesOptions.length
        ? this.categoryId
          ? this.categoriesOptions.filter((c) => c.id == this.categoryId)
          : this.categoriesOptions
        : [];
    },
  },
  methods: {
    selectCategory(value) {
      this.categoryId = value;
    },
  },
};
</script>
