<template>
  <div>
    <highcharts :options="chartOptions" v-if="chartOptions"></highcharts>
    <div v-if="showSource" class="data-source">
      <p v-if="metric.display_source && metric.sources">
        <b class="data-source-title">Data Source</b>
        <span v-for="source in metric.sources" :key="source.label" style="margin-right: 1ch;">
          <a :href="source.url" target="_blank" class="data-source-link" v-if="source.url">{{ source.label }}</a>
          <span v-else>{{ source.label }}</span>
        </span>
      </p>
      <p v-if="metric.calculation_footnote && metric.display_calculation_footnote">
        <b class="data-source-title">Metric Details</b>{{ metric.calculation_footnote }}
      </p>
      <p v-if="Object.keys(footnotes.notes).length">
        <b class="data-source-title">Footnotes</b>
        <span v-for="(label, note, index) in footnotes.notes" :key="index">
          <span v-if="index > 0">, </span><a :href="`#${footnotes.prefix}${index + 1}`" class="data-source-link">
            {{ footnotes.prefix }}{{ index + 1 }}
          </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { round } from "../utils";
import exportChart from "../chartDownload";

export default {
  props: [
    "metric",
    "records",
    "yearFilter",
    "cities",
    "strataFilter",
    "strataLabels",
    "isCrossClassified",
    "geoStrataFilter",
    "sortBy",
    "title",
    "showSource",
    "footnotes",
    "minValue",
    "maxValue",
  ],

  data() {
    return { loading: false }
  },

  watch: {
    // When chart parameters change, force it to rerender because heighcharts messes up the series
    records: function () { this.foceRefresh() },
    geoStrataFilter: function () { this.foceRefresh() },
    strataFilter: function () { this.foceRefresh() },
    sortBy: function () { this.foceRefresh() },
    yearFilter: function () { this.foceRefresh() },
  },

  computed: {
    strataValues: function () {
      return this.strataFilter
        ? this.strataLabels[this.strataFilter].values
        : [];
    },

    years: function () {
      return this.yearFilter ? [this.yearFilter] : this.metric.years;
    },

    chartOptions: function () {
      if (!this.metric.idn || this.loading) return false;
      // 1 year not stratified -> 1 bar chart - city series
      // 1 year stratified -> 1 bar chart - city series
      // Multiple years not stratified -> 1 line chart - year series
      // Multiple years stratified -> Multiple line charts from charts group component - year series
      const isTimeSeries = this.years.length > 1;
      const cities = isTimeSeries
        ? this.cities
        : this.cities.filter((city) => !!city.state);
      const groupedCities = this.groupCitiesByGeoStrata(cities);
      const series = isTimeSeries
        ? this.getTimeSeries()
        : this.strataFilter
          ? this.getStratifiedCitiesSeries(groupedCities)
          : this.getSimpleCitiesSeries(groupedCities);

      const xAxisPlotlines =
        this.geoStrataFilter && !isTimeSeries
          ? this.getGeoStrataPlotLines(groupedCities)
          : [];

      const allValues = Object.values(series)
        .map((s) => s.data.map((v) => (isNaN(v) ? v.y : v)))
        .flat();

      const maxValue = this.maxValue || (
        this.metric.max_y_axis_value
          ? Math.max(this.metric.max_y_axis_value, ...allValues)
          : undefined)

      const sources = this.metric.display_source && this.metric.sources;
      const sourcesText = sources.length ? sources.map(s => s.label).join(" ") : null;
      const sourceHeight = sources.length ? (parseInt(sourcesText.length / 50) || 1) * 20 : undefined;
      const writeCSV = this.writeCSV;

      return {
        series: series,
        chart: {
          type: isTimeSeries ? "line" : "bar",
          height:
            cities.length > 6
              ? 700
              : cities.length > 4 && !isTimeSeries && this.strataFilter
                ? 600
                : undefined,
        },
        title: {
          text: this.title || this.getTitle(),
        },
        subtitle: {
          text: this.metric.subtitle,
        },
        xAxis: {
          categories: isTimeSeries ? this.years : undefined,
          plotLines: xAxisPlotlines,
          labels: {
            animate: true,
          },
          type: 'category',
        },
        yAxis: {
          title: {
            text: this.metric.y_axis_units_label,
          },
          min: !isNaN(this.minValue)
            ? this.minValue
            : !isNaN(this.metric.min_y_axis_value)
              ? Math.min(this.metric.min_y_axis_value, ...allValues)
              : undefined,
          max: maxValue,
          plotLines: !isTimeSeries && this.getYAxisPlotLines(cities.length, maxValue),
        },
        plotOptions: {
          series: {
            connectNulls: true,
          },
        },
        legend: {
          enabled: this.strataFilter || series.length > 1,
          labelFormatter: function () {
            const hasData =
              this.yData.filter((i) => {
                if (!!i && i.length) {
                  return !!i[0];
                } else {
                  return !!i;
                }
              }).length > 0;
            return hasData ? this.name : `${this.name} [Unavailable]`;
          },
        },
        tooltip: {
          style: { fontSize: "14px" },
        },
        exporting: {
          sourceWidth: 500,
          chartOptions: sourcesText ? {
            chart: {
              spacingBottom: sourceHeight + 24,
              events: {
                load: function () {
                  this.renderer.text(
                    `<b/>Data Source</b> ${sourcesText}`,
                    12,
                    this.chartHeight - sourceHeight + 12,
                    true,
                  ).css({
                    'font-size': '10px',
                    'width': this.chartWidth - 24,
                  }).add();
                }
              },
            }
          } : {},
          menuItemDefinitions: {
            downloadData: {
              text: "Download Data" + (this.metric.download_allowed ? "" : " (not available)"),
              onclick: this.metric.download_allowed ? writeCSV : null,
            },
          },
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "separator", "downloadData"],
            },
          },
        },
      };
    },
  },

  methods: {
    // Series

    filterTimeSeriesRecords() {
      const records = [];
      const isStratified = this.strataFilter;
      const metricIsStratified = this.metric.available_strata.length > 0;

      this.records.forEach((record) => {
        const stratified = Object.values(record.strata).filter(
          (s) => s.is_stratified
        );
        const recordHasStrataField = Object.values(record.strata).length > 0;
        if (
          isStratified ||
          !metricIsStratified ||
          (recordHasStrataField && !stratified.length)
        ) {
          records.push(record);
        }
      });
      return records;
    },

    getTimeSeries() {
      // Years are used as category markers in X axis
      // Each city is a series (line on the chart) having one value for each year
      // (null values are ignored and highcharts continues the line)
      const emptyYearsMap = Object.fromEntries(
        this.years.map((y) => [Number(y), null])
      );
      // { 123: { 2020: null, 2021: null } }  # city.id = 123
      const citiesSeries = Object.fromEntries(
        this.cities.map((c) => [c.id, { ...emptyYearsMap }])
      );

      this.filterTimeSeriesRecords().forEach((record) => {
        citiesSeries[record.city][Number(record.date_label)] = round(
          record.value
        );
      });

      // years: [ 2020, 2021 ]
      // [ { name: "New York, NY", data: [ { y: 1.1 }, { y: 1.3 } ] } ]
      const series = this.cities.map((city, index) => {
        const data = Object.values(citiesSeries[city.id]).map((value) => ({
          y: value,
        }));
        if (!city.state)
          data[data.length - 1]["dataLabels"] = {
            enabled: true,
            formatter: () => city.label,
          };
        return {
          name: city.label,
          data: data,
          color: !city.state ? "#1B2A46" : undefined,
          lineWidth: index == 0 ? 3 : 2,
          dashStyle: city.state ? "Solid" : "ShortDash",
          zIndex: index == 0 ? this.cities.length : 0,
          marker: { symbol: "circle" },
        };
      });
      return series;
    },

    filterSimpleCitiesRecords() {
      const records = [];
      const metricIsStratified = this.metric.available_strata.length > 0;
      this.records.forEach((record) => {
        const stratified = Object.values(record.strata).filter(
          (s) => s.is_stratified
        );
        const recordHasStrataField = Object.values(record.strata).length > 0;
        if (
          !metricIsStratified ||
          (recordHasStrataField && !stratified.length)
        ) {
          records.push(record);
        }
      });
      return records;
    },

    getSimpleCitiesSeries(geoStrataGroup) {
      // Cities are used as category markers in Y axis
      // A single series contains a point for each city

      // { 123: null, 456: null }
      const citiesData = Object.fromEntries(this.cities.map((c) => [c.id, null]));
      const mainCityId = this.cities[0].id;

      this.filterSimpleCitiesRecords().forEach((record) => {
        citiesData[record.city] = round(record.value);
      });

      function sortByName(a, b) {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      }

      function sortByValue(a, b) {
        if (a.y > b.y) return -1
        if (a.y < b.y) return 1
        return 0
      }

      const sortBy = this.sortBy == "name" ? sortByName : sortByValue;

      // [
      //   {
      //     name: "Metric X in 2020",
      //     data: [
      //       { label: "Austin, TX", name: "<b>Austin, TX</b>", y: 10.5 },  <-main city
      //       { label: "Boston, MA", name: "Boston, MA", y: 15.1 },
      //     ]
      //   },
      // ]
      const groupsData = geoStrataGroup
        .map((group) => {
          return group.cities
            .map((c) => {
              const sufix = !citiesData[c.id] ? " [N/A]" : ""
              return {
                label: c.label,
                name: c.id == mainCityId ? `<b>${c.label}${sufix}</b>` : `${c.label}${sufix}`,
                y: citiesData[c.id],
              }
            }).sort(sortBy);
        });
      return [
        {
          name: this.yearFilter,
          data: groupsData.flat(),
        },
      ];
    },

    filterStratifiedCitiesRecords() {
      const records = [];

      this.records.forEach((record) => {
        const recordStrata = record.strata[this.strataFilter];
        if (recordStrata) {
          const otherStrata = Object.entries(record.strata).filter(
            ([label, srtataObj]) =>
              label != this.strataFilter && srtataObj.is_stratified
          );
          // Only use records not stratified by other strata for simple stata
          if (!otherStrata.length || this.isCrossClassified) {
            records.push(record);
          }
        }
      });

      return records;
    },

    getStratifiedCitiesSeries(geoStrataGroups) {
      // Cities are used as category markers in Y axis
      // Each strata value is a series (groups of bars on the chart identified by color)
      // having one value for each city
      // null values must be in the correct order matching the city position

      const mainCityId = this.cities[0].id;
      const groupCities = geoStrataGroups.map(group => group.cities);
      const cities = groupCities.flat();
      const emptyCitiesMap = Object.fromEntries(
        cities.map((c) => [c.id, null])
      );

      // {
      //   "Female": { color: "#000", citiesData: { 123: 1, 456: 2 } },
      //   "Male": { color: "#666", citiesData: { 456: null, 123: 1.5 } },
      // }
      const strataSeries = Object.fromEntries(
        this.strataValues.map((s) => [
          s.value,
          { color: s.color, citiesData: { ...emptyCitiesMap } },
        ])
      );

      this.filterStratifiedCitiesRecords().forEach((record) => {
        const recordStrata = record.strata[this.strataFilter];
        if (recordStrata) {
          const strataValue = recordStrata.value;

          if (strataSeries[strataValue] == undefined) {
            strataSeries[strataValue] = { citiesData: { ...emptyCitiesMap } };
          }

          strataSeries[strataValue]["citiesData"][record.city] = round(
            record.value
          );
        }
      });

      // [
      //    {
      //      name: "Female",
      //      color: "#000",
      //      data: [
      //         { label: "Austin, TX", name: "<b>Austin, TX</b>", y: 10.5 },  <-main city
      //         { label: "Boston, MA", name: "Boston, MA", y: null },
      //       ],
      //    },
      // ]
      const series = [];
      Object.entries(strataSeries).forEach(([name, config]) => {
        const hasData =
          Object.values(config.citiesData).filter((d) => d != null).length > 0;
        if (hasData) {
          series.push({
            name: name,
            color: config.color,
            data: cities.map((c) => ({
              name: c.id == mainCityId ? `<b>${c.label}</b>` : c.label,
              y: config.citiesData[c.id],
            })),
          });
        }
      });
      return series;
    },

    // Helpers

    getNoStateCities() {
      // Get "cities" withouth a state (U.S. Total) and check if they have data
      const noStateCities = Object.fromEntries(
        this.cities.filter((c) => !c.state).map((c) => [c.id, { ...c }])
      );
      if (Object.keys(noStateCities).length > 0) {
        this.records.forEach((record) => {
          if (noStateCities[record.city]) {
            const notStratified = Object.values(record.strata).filter(
              (strata) => !strata.is_stratified
            );
            if (notStratified) noStateCities[record.city].value = record.value;
          }
        });
      }

      return Object.values(noStateCities).filter(c => c.value);
    },

    groupCitiesByGeoStrata(cities) {
      // [{ label: "West", cities: [...] }]
      if (this.geoStrataFilter) {
        const geoStrata = {};
        cities.forEach((city) => {
          const strata = city.strata[this.geoStrataFilter] || "[N/A]";
          if (!geoStrata[strata]) geoStrata[strata] = { cities: [] };
          geoStrata[strata].cities.push(city);
        });
        const labels = Object.keys(geoStrata).sort((a, b) => {
          if (a == "[N/A]") return 1
          if (b == "[N/A]") return -1
          if (a < b) return -1
          if (a > b) return 1
          return 0
        });
        return labels.map(label => ({ label, cities: geoStrata[label].cities }))
      }
      return [{ label: null, cities }]
    },

    getYAxisPlotLines(citiesLength, maxValue) {
      // Get non-city locations (U.S. Total) and draw a vertical line on the
      //  bar chart if there's data available
      return this.getNoStateCities().map((city) => ({
        color: "#1B2A46",
        value: city.value,
        label: {
          text: city.name,
          rotation: 0,
          y: citiesLength > 7 ? 20 : 10,
          align: maxValue && maxValue < city.value ? "right" : "left",
          style: {
            color: "#4F4F51",
            fontWeight: "bold",
            "text-shadow": "2px 2px #efefef",
          },
        },
        dashStyle: "LongDash",
        zIndex: citiesLength,
      }));
    },

    getGeoStrataPlotLines(geoStrataGroups) {
      // Plot lines config obj for each geo strata group
      // value is determined by the order and amount of cities in each group
      let count = 0;
      return geoStrataGroups.map((group) => {
        const config = {
          value: count - 0.5,
          label: {
            text: group.label,
            align: "right",
            style: { color: "#4F4F51", fontWeight: "bold" },
          },
          color: "#1B2A46",
          zIndex: this.cities.length,
        };
        count += group.cities.length;
        return config;
      });
    },

    getTitle() {
      let title = this.metric.label;
      if (this.strataFilter) {
        const label = this.strataLabels[this.strataFilter].label;
        title += ` by ${label}`;
      }
      if (this.yearFilter) {
        title += ` in ${this.yearFilter}`;
      } else if (this.metric.years.length == 1) {
        title += ` in ${this.metric.years[0]}`;
      } else {
        title += ", All Years";
      }
      return title;
    },

    foceRefresh() {
      this.loading = true;
      this.$nextTick(function () {
        this.loading = false;
      })
    },

    // Download

    writeCSV() {
      const cities = Object.fromEntries(this.cities.map(i => ([i.id, i])))
      const years = this.yearFilter ? [this.yearFilter] : this.metric.years;
      const records = years.length > 1
        ? this.filterTimeSeriesRecords()
        : this.strataFilter
          ? this.filterStratifiedCitiesRecords()
          : this.filterSimpleCitiesRecords();
      const title = `${this.title || this.getTitle()}.csv`
      exportChart(this.metric, cities, this.strataFilter, records, title)
    },
  },

  components: {
    highcharts: Chart,
  },
};
</script>
