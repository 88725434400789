<template>
  <div>
    <highcharts :options="chartOptions" v-if="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { fetchCity } from "../api";
import exportChart from "../chartDownload";

export default {
  props: [
    "metric",
    "groupedRecords",
    "cityId",
    "yearFilter",
    "title",
    "cities",
    "inequity",
  ],

  computed: {
    strataValues: function () {
      return this.strataFilter
        ? this.strataLabels[this.strataFilter].values
        : [];
    },

    chartOptions: function () {
      if (!this.metric.idn) return false;

      const series = this.cities
        ? this.getCitiesSeries()
        : this.getSeries();

      const [minValue, maxValue] = this.cities
        ? this.getMinMax(series)
        : this.getSeriesMinMax(series);

      const metricLabel = this.metric.label.toLowerCase();
      const metricUnit = this.metric.metric_unit_flag
      const units = metricUnit === 'percent' ? "percentage point" : this.metric.y_axis_units_label.toLowerCase();

      const sources = this.metric.display_source && this.metric.sources;
      const sourcesText = sources.length ? sources.map(s => s.label).join(" ") : null;
      const sourceHeight = sources.length ? (parseInt(sourcesText.length / 50) || 1) * 20 : undefined;
      const writeCSV = this.writeCSV;

      return {
        chart: {
          type: "bar",
          height: this.cities ? 700 : 400,
        },
        title: {
          text: this.title || `${this.metric.inequity_label} Inequities in ${this.metric.label}, ${this.yearFilter}`,
        },
        subtitle: {
          text: this.metric.subtitle,
        },
        xAxis: this.cities ? {
          categories: undefined,
          type: 'category',
        } : {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          title: {
            text: `Difference (${units})`,
          },
          plotLines: [{
            value: 0,
            width: 2,
            color: "#1B2A46",
            zIndex: 100,
          }],
          min: minValue < 0 ? minValue : -1,
          max: maxValue > 0 ? maxValue : 1,
        },
        tooltip: {
          pointFormatter: function () {
            const percentComparative = this.y == 0
              ? "equal percentage points"
              : this.y > 0
                ? "percentage points higher"
                : "percentage points lower";
            const comparative = this.y == 0
              ? "equal"
              : this.y > 0
                ? "more"
                : "less";

            const commonText = `Compared to <strong>${this.primaryLabel}</strong> persons,
                  <strong>${this.secondaryLabel}</strong> persons have `;

            let tooltipText;
            if (metricUnit === 'percent') {
              tooltipText = `${commonText}<strong>${Math.abs(this.y)}</strong> ${percentComparative} ${metricLabel}.
              (Difference = ${this.secondaryLabel} ${this.secondaryValue} minus ${this.primaryLabel} ${this.primaryValue})`;
            } else if (metricUnit === 'dollar') {
              tooltipText = `${commonText}<strong>$${Math.abs(this.y).toLocaleString()}</strong> ${comparative} ${metricLabel}.
              (Difference = ${this.secondaryLabel} $${this.secondaryValue.toLocaleString()} minus
              ${this.primaryLabel} $${this.primaryValue.toLocaleString()})`;
            } else {
              // default is rate
              tooltipText = `${commonText}<strong>${Math.abs(this.y)}</strong> ${comparative} ${metricLabel} ${units}.
              (Difference = ${this.secondaryLabel} ${this.secondaryValue} minus ${this.primaryLabel} ${this.primaryValue})`;
            }
            return !isNaN(this.y) && tooltipText;
          },
          headerFormat: this.cities ? "<strong>{point.key}</strong><br/>" : "",
        },
        legend: {
          enabled: !this.cities,
        },
        series: series,
        lang: {
          noData: "No data available for this selection.",
        },
        noData: {
          style: {
            fontWeight: "bold",
            fontSize: "15px",
            color: "#303030",
          },
        },
        exporting: {
          sourceWidth: 500,
          chartOptions: sourcesText ? {
            chart: {
              spacingBottom: sourceHeight + 24,
              events: {
                load: function () {
                  this.renderer.text(
                    `<b/>Data Source</b> ${sourcesText}`,
                    10,
                    this.chartHeight - sourceHeight,
                    true,
                  ).css({
                    'font-size': '10px',
                    'width': this.chartWidth - 24,
                  }).add();
                }
              },
            }
          } : {},
          menuItemDefinitions: {
            downloadData: {
              text: "Download Data" + (this.metric.download_allowed ? "" : " (not available)"),
              onclick: this.metric.download_allowed ? writeCSV : null,
            },
          },
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "separator", "downloadData"],
            },
          },
        }
      };
    },
  },

  methods: {
    getSeries: function () {
      return this.groupedRecords.map((group) => {
        const cityData = group.recordsByCity[this.cityId];
        const sufix = !cityData.diff ? " [N/A]" : "";
        return {
          name: `${group.label}${sufix}`,
          color: group.color,
          data: [{
            y: cityData.diff,
            primaryValue: cityData.primaryValue,
            secondaryValue: cityData.secondaryValue,
            primaryLabel: group.primaryLabel,
            secondaryLabel: group.secondaryLabel,
          }],
        }
      });
    },

    getCitiesSeries: function () {
      const inequityConfig = this.groupedRecords.filter(g => g.label == this.inequity.label)[0];
      const data = this.cities.map(city => {
        const cityData = inequityConfig.recordsByCity[city.id];
        const sufix = !cityData.diff ? " [N/A]" : "";
        return {
          ...cityData,
          y: cityData.diff,
          name: `${city.label}${sufix}`,
          primaryLabel: inequityConfig.primaryLabel,
          secondaryLabel: inequityConfig.secondaryLabel,
        }
      })
      return [{
        name: "",
        color: this.inequity.color,
        data
      }]
    },

    getMinMax: function () {
      const allDiffs = this.groupedRecords.map(g => Object.values(g.recordsByCity).map(i => i.diff)).flat()
      return [Math.min(...allDiffs), Math.max(...allDiffs)]
    },

    getSeriesMinMax: function (series) {
      const allDiffs = series.map(s => s.data.map(d => d.y)).flat()
      return [Math.min(...allDiffs), Math.max(...allDiffs)]
    },

    // Download

    writeCSV() {
      const records = [];
      const groupedRecords = this.cities
        ? this.groupedRecords.filter(group => group.label == this.inequity.label)
        : this.groupedRecords;
      groupedRecords.map(
        group => Object.values(group.recordsByCity).map(
          (cityRecords) => {
            if (cityRecords.primaryRecord) records.push(cityRecords.primaryRecord);
            if (cityRecords.secondaryRecord) records.push(cityRecords.secondaryRecord);
          }
        )
      );
      const title = this.title || `${this.metric.inequity_label} Inequities in ${this.metric.label}, ${this.yearFilter}`;
      const metric = this.metric;
      const strataFilter = groupedRecords[0].strataSlug;
      if (this.cities) {
        const cities = Object.fromEntries(this.cities.map(i => ([i.id, i])))
        exportChart(metric, cities, strataFilter, records, `${title}.csv`)
      } else {
        fetchCity({ cityId: this.cityId }).then((city) => {
          const cities = Object.fromEntries([[city.id, city]])
          exportChart(metric, cities, strataFilter, records, `${title}.csv`)
        })
      }
    },
  },

  components: {
    highcharts: Chart,
  },
};
</script>
