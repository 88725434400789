<template>
  <dialog ref="dialog">
    <button class="close-button" @click="close">Close</button>
    <div class="scroll-wrap">

      <slot name="header">
        <div class="modal-header">
          <h2 class="modal-heading">{{ title }}</h2>
        </div>
      </slot>

      <slot name="body" :close="close">
        <div class="modal-body">
        </div>
      </slot>

    </div>
  </dialog>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default {
  props: ["title"],
  mounted() {
    const dialogElement = this.$refs.dialog;
    const dialogElementScrollWrap = dialogElement.querySelector('.scroll-wrap');
    dialogElement.showModal();
    disableBodyScroll(dialogElementScrollWrap);
  },
  methods: {
    close() {
      const dialogElement = this.$refs.dialog;
      const dialogElementScrollWrap = dialogElement.querySelector('.scroll-wrap');
      dialogElement.close()
      if (dialogElementScrollWrap) enableBodyScroll(dialogElementScrollWrap);
      this.$emit("close-modal");
    }
  },
}
</script>
