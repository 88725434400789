<template>
  <div class="charts-group">
    <h3 class="graph-group-title">{{ title }}</h3>

    <ul class="graph-list">
      <li
        class="graph-item"
        v-for="(filteredRecords, strataValue) in groupedRecords"
        :key="strataValue"
      >
        <cities-chart
          :metric="metric"
          :records="filteredRecords"
          :yearFilter="yearFilter"
          :strataFilter="isCross ? secondaryStrata : strataFilter"
          :strataLabels="isCross ? secondaryStrataLabels : strataLabels"
          :isCrossClassified="isCross"
          :cities="cities"
          :geoStrataFilter="geoStrataFilter"
          :title="`${metric.label} by ${strataLabel}: ${strataValue}, ${yearFilter || allYearsLabel}`"
          :showSource="false"
          :minValue="minValue"
          :maxValue="maxValue"
        >
        </cities-chart>
      </li>
    </ul>
    <div class="data-source">
      <p v-if="metric.display_source && metric.sources">
        <b class="data-source-title">Data Source</b>
        <span v-for="source in metric.sources" :key="source.label" style="margin-right: 1ch;">
          <a :href="source.url" target="_blank" class="data-source-link" v-if="source.url">{{ source.label }}</a>
          <span v-else>{{ source.label }}</span>
        </span>
      </p>
      <p v-if="metric.calculation_footnote && metric.display_calculation_footnote">
        <b class="data-source-title">Metric Details</b>{{ metric.calculation_footnote }}
      </p>
      <p v-if="footnotes && Object.keys(footnotes.notes).length">
        <b class="data-source-title">Footnotes</b>
        <span v-for="(label, note, index) in footnotes.notes" :key="index">
          <span v-if="index > 0">, </span><a :href="`#${footnotes.prefix}${index + 1}`" class="data-source-link">
            {{ footnotes.prefix }}{{index + 1}}
          </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import citiesChart from "./citiesChart";

export default {
  props: [
    "metric",
    "records",
    "yearFilter",
    "cities",
    "strataFilter",
    "strataLabels",
    "geoStrataFilter",
    "footnotes",
  ],

  data() {
    return {
      // if isCross: { "race": ["Hispanic", "Black"] }
      secondaryStrataLabels: {},
      allYearsLabel: "All Years",
      minValue: undefined,
      maxValue: undefined,
    }
  },

  computed: {
    strataConfig: function () {
      return this.strataFilter ? this.strataLabels[this.strataFilter] : {};
    },

    strataLabel: function () {
      return this.strataConfig && this.strataConfig.label;
    },

    strataValues: function () {
      return this.strataConfig ? this.strataConfig.values : [];
    },

    isCross: function () {
      return this.strataConfig.is_cross;
    },

    primaryStrata: function () {
      return this.isCross && this.strataFilter.split("_")[1]
    },

    primaryStrataValues: function () {
      return this.primaryStrata
        ? this.strataLabels[this.primaryStrata].values
        : [];
    },

    primaryStrataLabel: function () {
      return this.primaryStrata && this.strataLabels[this.primaryStrata].label
    },

    secondaryStrata: function () {
      return this.isCross && this.strataFilter.split("_")[0]
    },

    secondaryStrataValues: function () {
      return this.secondaryStrata
        ? this.strataLabels[this.secondaryStrata].values
        : [];
    },

    title: function () {
      const sufix = this.yearFilter ? ` in ${this.yearFilter}` : ", All Years"
      return `${this.metric.label} by ${this.strataLabel}${sufix}`;
    },

    groupedRecords() {
      let groupedRecords;
      let minValue;
      let maxValue;

      if (this.isCross && this.yearFilter) {
        // Split cross-classified records by their primary strata values
        // Each of the primary strata labels will be one chart stratified
        // by the secondary strata

        // { "Female": [], "Male": [] }
        groupedRecords = Object.fromEntries(
          this.primaryStrataValues.map((s) => [s.value, []])
        );
        const secondaryStrataValues = new Set();

        this.records.forEach((record) => {
          const strataObj = record.strata && record.strata[this.strataFilter];
          const value = strataObj && strataObj.value;  // "Hispanic Female"
          const primaryStrataObj = record.strata && record.strata[this.primaryStrata];
          const primaryStrataValue = primaryStrataObj && primaryStrataObj.value;  // "Female"
          const secondaryStrataObj = record.strata && record.strata[this.secondaryStrata];
          const secondaryStrataValue = secondaryStrataObj && secondaryStrataObj.value;  // "Hispanic"
          if (value) {
            if (groupedRecords[primaryStrataValue] == undefined) groupedRecords[primaryStrataValue] = [];
            groupedRecords[primaryStrataValue].push(record);
            secondaryStrataValues.add(secondaryStrataValue);
            minValue = minValue ? Math.min(record.value, minValue) : record.value;
            maxValue = maxValue ? Math.max(record.value, maxValue) : record.value;
          }
        });

        const labelConfig = this.strataLabels[this.secondaryStrata];
        this.secondaryStrataLabels[this.secondaryStrata] = {
          ...labelConfig,
          values: labelConfig.values.filter(c => secondaryStrataValues.has(c.value)),
        }
      } else {
        groupedRecords = Object.fromEntries(
          this.strataValues.map((s) => [s.value, []])
        );
        this.records.forEach((record) => {
          const strataObj = record.strata && record.strata[this.strataFilter];
          const value = strataObj && strataObj.value;
          const otherStrata = Object.entries(record.strata).filter(([k, s]) => k != this.strataFilter && s.is_stratified).length
          if (value && (!otherStrata || this.isCross)) {
            if (groupedRecords[value] == undefined) groupedRecords[value] = [];
            groupedRecords[value].push(record);
            minValue = minValue ? Math.min(record.value, minValue) : record.value;
            maxValue = maxValue ? Math.max(record.value, maxValue) : record.value;
          }
        });
      }

      this.minValue = !isNaN(this.metric.min_y_axis_value) ? Math.min(this.metric.min_y_axis_value, minValue) : minValue;
      this.maxValue = !isNaN(this.metric.max_y_axis_value) ? Math.max(this.metric.max_y_axis_value, maxValue) : maxValue;

      return Object.fromEntries(
        Object.entries(groupedRecords).filter(
          ([value, records]) => records.length > 0
        )
      );
    },
  },

  components: {
    citiesChart: citiesChart,
  },
};
</script>
