<template>
  <div>
    <div class="select-group-wrap">
      <ul class="select-group-list">
        <li>
          <div class="select-group-item">
            <div class="col w2">
              <div class="field">
                <label>Metric</label>
                <v-select
                  :options="metricOptions"
                  :reduce="(option) => option.idx"
                  :value="!loadingMetrics && selectedMetric.idx ? selectedMetric.idx : null"
                  :placeholder="loadingMetrics ? 'Loading metrics' : 'Choose a metric'"
                  :filterBy="filterMetricOptions"
                  :clearable="false"
                  @input="onSelectMetric"
                  :selectable="(option) => option.type == 'option'"
                >
                  <template #option="{ label, type }">
                    <span :class="{
                  'vs-item': type == 'option',
                  'vs-item-heading': type == 'heading',
                  'vs-item-subheading': type == 'subheading',
                }">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
                <button class="link-button" id="show-modal" @click="onShowModal">Browse by category</button>
              </div>
            </div>
            <div class="col w2">
              <div class="field">
                <label>City</label>
                <v-select
                  :options="cityOptions"
                  :reduce="(option) => option.id"
                  :value="!loadingCities && selectedCity.id ? selectedCity.id : null"
                  :clearable="false"
                  :placeholder="loadingCities ? 'Loading cities' : 'Choose city'"
                  @input="onSelectCity"
                >
                  <template v-slot:option="option">
                    <span :class="{ 'dropdown-highlight': option.id == 'All' }">{{
                  option.label
                }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col w1">
              <div class="field">
                <label>Year</label>
                <v-select
                  @input="onSelectYear"
                  :reduce="(option) => option.value"
                  :options="yearOptions"
                  :value="selectedYear"
                  :selectable="(option) => !option.disabled"
                  :clearable="false"
                ></v-select>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <main class="page-section" v-if="!loadingRecords && records.length">
      <ul class="graph-list">
        <li :class="{
                  'graph-item': selectedCity.id != 'All',
                  'graph-group': selectedCity.id == 'All',
                }" :ref="'metric-0'">
          <inequities-chart v-if="selectedCity.id != 'All' && records.length" :metric="selectedMetric"
            :groupedRecords="groupedRecords" :cityId="selectedCity.id" :yearFilter="yearFilter">
          </inequities-chart>
          <div v-if="selectedCity.id == 'All' && records.length" class="charts-group">
            <h3 class="graph-group-title">
              {{ selectedMetric.inequity_label }} Inequities in {{ selectedMetric.label }} in {{ yearFilter }}
            </h3>
            <ul class="graph-list">
              <li class="graph-item" v-for="inequity in groupedRecords.filter(g => g.hasRecords)" :key="inequity.label">
                <inequities-chart :metric="selectedMetric" :yearFilter="yearFilter" :groupedRecords="groupedRecords"
                  :inequity="inequity" :cities="cityOptions.filter(c => c.id != 'All')"
                  :title="`${inequity.label} in ${selectedMetric.label}, ${yearFilter}`">
                </inequities-chart>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <div v-if="records.length &&
                  selectedMetric.display_source &&
                  selectedMetric.sources
                  " class="footnotes">
        <h5 class="footnotes-title">Data Source</h5>
        <p class="footnote">
          <span v-for="source in selectedMetric.sources" :key="source.label" style="margin-right: 1ch;">
            <a v-if="source.url" :href="source.url" target="_blank" class="data-source-link">{{ source.label }}</a>
            <span v-else>{{ source.label }}</span>
          </span>
        </p>
      </div>

      <div v-if="selectedMetric.calculation_footnote && selectedMetric.display_calculation_footnote" class="footnotes">
        <h5 class="footnotes-title">Metric Details</h5>
        <p class="footnote">{{ selectedMetric.calculation_footnote }}
        </p>
      </div>

      <div class="footnotes" v-if="records.length && Object.keys(footnotesConfig.notes).length">
        <h5 class="footnotes-title">Footnotes</h5>
        <div class="footnote-group" v-if="selectedCity.id != 'All'">
          <div v-for="(label, note, i) in footnotesConfig.notes" :key="i" class="footnote">
            <p>
              <strong>{{ label }}</strong> {{ note }}
            </p>
          </div>
        </div>
        <div v-else class="footnote-group">
          <div v-for="(labels, note, i) in footnotesConfig.notes" :key="i" class="footnote">
            <p v-for="(cities, label) in labels" :key="label">
              {{ cities }}<br />
              <strong>{{ label }}</strong>{{ note }}<br />
            </p>
          </div>
        </div>
      </div>
    </main>

    <main class="page-section" v-if="records.length <= 0">
      <p v-if="loadingCities || loadingMetrics" class="empty-state">
        Loading...
      </p>
      <div v-else>
        <div v-if="selectedCity.id && selectedMetric.id">
          <p v-if="loadingRecords">Loading...</p>
          <p class="empty-state" v-else>
            No data available for {{ selectedMetric.label }} in
            {{ selectedCity.label }}
          </p>
        </div>
        <p class="empty-state" v-else>
          Select a metric and a city to see the data.
        </p>
      </div>
    </main>

    <metrics-modal :showModal="showModal" :categoriesOptions="categoriesOptions" :empty="!Object.keys(metrics).length"
      @close-modal="showModal = false" @metric-selected="onSelectMetric">
    </metrics-modal>
  </div>
</template>

<script>
import {
  fetchCities,
  fetchRecords,
  fetchCategories,
  fetchInequities,
  fetchMetric,
} from "./api";
import {
  buildFootnotes,
  buildCitiesFootnotes,
  round,
  isNumber,
  getMetricOptions,
  filterOptions,
} from "./utils";

import metricsModal from "./components/metricsModal";
import inequitiesChart from "./components/inequitiesChart";

const emptyStrataLabel = { slug: null, label: null }
const emptyMetric = {
  idn: null,
  id: null,
  label: "",
  y_axis_units_label: "",
  years: [],
  values: [],
  strata_label: { ...emptyStrataLabel },
};
const emptyCity = {
  id: null,
  label: null,
};

export default {
  name: "inequities",

  data() {
    return {
      loadingMetrics: true,
      loadingCities: true,
      loadingRecords: true,
      metrics: {},
      cities: {},
      strataLabels: {},
      selectedMetric: { ...emptyMetric },
      selectedCity: { ...emptyCity },
      records: [],
      categoriesOptions: [],
      showModal: false,
      yearFilter: null,
    };
  },

  computed: {
    metricOptions: function () {
      return getMetricOptions(this.categoriesOptions)
    },

    cityOptions: function () {
      return Object.values(this.cities).sort(function (a, b) {
        if (!a.state) return -1;
        if (!b.state) return 1;
        if (a.label < b.label) return -1;
        if (b.label < a.label) return 1;
        return 0;
      })
    },

    footnotesConfig: function () {
      const notes = this.selectedCity.id == "All"
        ? buildCitiesFootnotes([{ records: this.records }], this.cities)
        : buildFootnotes([{ records: this.records }]);
      return notes ? notes[0] : { notes: {} };
    },

    yearOptions: function () {
      return this.selectedMetric.years.map((y) => ({ label: y, value: y }))
    },

    selectedYear: function () {
      const options = this.yearOptions;
      return this.yearFilter
        ? options.filter((o) => o.value == (this.yearFilter))[0]
        : options[options.length - 1];
    },

    groupedRecords: function () {
      const strataSlug = this.selectedMetric.strata_label.slug;
      const strataValueLabels = [...new Set(
        this.selectedMetric.values.map(v => ([v.primary_value, v.secondary_value])).flat()
      )];
      const cityIds = Object.keys(this.cities).filter(id => id != "All");

      // {
      //   "White": {"cityId1": {Record}, "cityId2": {Record}},
      //   "Black": {"cityId1": {Record}, "cityId2": {Record}},
      // }
      const grouped = Object.fromEntries(strataValueLabels.map(l => ([l, {}])));
      this.records.forEach((record) => {
        const strata = record.strata[strataSlug];
        const isCross = Object.entries(record.strata)
          .filter(([slug, value]) => slug != strataSlug && value.is_stratified).length;
        if (strata.is_stratified && !isCross) {
          grouped[strata.value][record.city] = record;
        };
      });

      return this.selectedMetric.values.map((inequity) => {
        const primaryLabel = inequity.primary_value;
        const secondaryLabel = inequity.secondary_value;
        const recordsByCity = Object.fromEntries(cityIds.map((city) => {
          const primaryRecord = grouped[primaryLabel][city];
          const secondaryRecord = grouped[secondaryLabel][city];
          const primaryValue = primaryRecord && isNumber(primaryRecord.value) ? round(primaryRecord.value) : null;
          const secondaryValue = secondaryRecord && isNumber(secondaryRecord.value) ? round(secondaryRecord.value) : null;
          const diff = isNumber(primaryValue) && isNumber(secondaryValue)
            ? round(secondaryValue - primaryValue)
            : null;
          const config = {
            primaryRecord,
            secondaryRecord,
            primaryValue,
            secondaryValue,
            diff,
            hasRecords: !!primaryRecord && !!secondaryRecord,
          }
          return [city, config]
        }));
        return {
          primaryLabel,
          secondaryLabel,
          strataSlug,
          color: inequity.color,
          label: inequity.label,
          inequity_label: inequity.inequity_label,
          recordsByCity: recordsByCity,
          hasRecords: Object.values(recordsByCity).filter(i => i.hasRecords).length > 0,
        }
      });
      // [
      //   {
      //     primaryLabel: "White",
      //     secondaryLabel: "Black",
      //     color: "#FF0",
      //     label: "White-Black Difference",
      //     recordsByCity: {
      //       "1": {
      //         primaryRecord: {WhiteRecord},
      //         secondaryRecord: {BlackRecord},
      //         primaryValue: 100,
      //         secondaryValue: 90,
      //         diff: -10,
      //       }
      //     }
      //   },
      // ]
    },
  },

  created() {
    Promise.all([fetchInequities(), fetchCategories(), fetchCities()]).then(
      ([inequities, categories, cities]) => {
        const inequityObjs = {};
        const categoryIds = [];
        const subcategoryIds = [];
        const groupedMetrics = {};

        inequities.forEach(inequity => {
          const metric = inequity.metric;
          const merged = {
            ...metric,
            values: inequity.values,
            strata_label: inequity.strata_label,
            inequity_label: inequity.inequity_label,
            metric_unit_flag: inequity.metric_unit_flag,
            years: [],
            idx: `${metric.idn}_${inequity.strata_label.slug}`,
          }
          inequityObjs[merged.idx] = merged;
          categoryIds.push(metric.category);
          subcategoryIds.push(metric.subcategory);
          if (!groupedMetrics[metric.subcategory]) groupedMetrics[metric.subcategory] = [];
          groupedMetrics[metric.subcategory].push(merged);
        })

        this.metrics = inequityObjs;

        const options = categories
          .filter(c => categoryIds.indexOf(c.id) >= 0)
          .map((category) => ({
            ...category,
            subcategories: category.subcategories
              .filter(s => subcategoryIds.indexOf(s.id) >= 0)
              .map((subcategory) => ({
                ...subcategory,
                metrics: groupedMetrics[subcategory.id],
              })),
          }));
        this.categoriesOptions = options;

        this.loadingMetrics = false;
        this.loadingCities = false;

        this.cities = {
          "All": { id: "All", label: "All cities" },
          ...Object.fromEntries(cities.filter((c) => c.state).map((c) => [c.id.toString(), c]))
        };

        const url = new URL(window.location);

        const urlCity = url.searchParams.get("city");
        const cityId = urlCity ? urlCity : null;
        if (cityId) this.selectedCity = this.cities[cityId];

        const urlYear = url.searchParams.get("year");
        this.yearFilter = urlYear;

        const urlMetric = url.searchParams.get("metric");
        const metricIdx = urlMetric
          ? this.metrics[urlMetric].idx
          : this.metrics.length
            ? Object.values(this.metrics)[0].idx
            : null;
        if (metricIdx) this.updateSelectedMetric(metricIdx);
      }
    );
  },

  methods: {
    onSelectMetric(metricIdx) {
      let idx;
      let metric;
      if (metricIdx.indexOf("_") > 0) {
        metric = this.metrics[metricIdx];
        idx = metricIdx;
      } else {
        metric = Object.values(this.metrics).filter(metric => metric.idn == metricIdx)[0];
        idx = metric.idx
      }
      this.selectedMetric = metric;
      this.showModal = false;
      this.loadingRecords = true;
      this.updateSelectedMetric(idx);
    },

    updateSelectedMetric(metricIdx) {
      const idn = metricIdx.split("_")[0];
      const cityId = this.selectedCity.id != "All" ? this.selectedCity.id : null;
      const metricId = this.metrics[metricIdx].id;
      fetchMetric({ metricId, available_for_city: cityId }).then(
        (metric) => {
          this.selectedMetric = { ...this.metrics[metricIdx], ...metric };
          this.yearFilter = this.yearFilter && metric.years.indexOf(this.yearFilter) >= 0
            ? this.yearFilter
            : metric.years[metric.years.length - 1];
          if (this.selectedCity.id) this.updateRecords();
          this.updateURL();
        }
      );
    },

    onSelectCity(cityId) {
      this.selectedCity = this.cities[cityId];
      this.loadingRecords = true;
      this.updateRecords();
      this.updateURL();
    },

    onSelectYear(year) {
      this.yearFilter = year;
      this.updateRecords();
      this.updateURL()
    },

    updateRecords() {
      const metricId = this.selectedMetric.id;
      const cityId = this.selectedCity.id == "All" ? null : this.selectedCity.id;
      const year = this.yearFilter;
      const values = this.selectedMetric.values.map(v => [v.primary_value, v.secondary_value]).flat();

      if (metricId) {
        fetchRecords({ metricId, cityId, year }).then((records) => {
          this.loadingRecords = false;
          const filtered = records.filter(record =>
            Object.values(record.strata).filter(strata =>
              values.indexOf(strata.value) >= 0
            ).length > 0
          )
          this.records = filtered;
          this.records.splice(filtered.length);
        });
      }
    },

    onShowModal() {
      this.showModal = true;
    },

    updateURL() {
      const metricIdx = this.selectedMetric.idx;
      const cityId = this.selectedCity.id;
      const url = new URL(window.location);

      if (metricIdx) url.searchParams.set("metric", metricIdx);
      if (cityId) url.searchParams.set("city", cityId);
      if (this.yearFilter) url.searchParams.set("year", this.yearFilter);

      history.pushState(
        { title: document.title, url: url.href },
        document.title,
        url.href
      );
    },

    filterMetricOptions(option, label, search) { return filterOptions(option, label, search) },
  },

  components: {
    metricsModal: metricsModal,
    inequitiesChart: inequitiesChart,
  },
};
</script>
