const apiURLs = {
  cities: "/api/cities/",
  categories: "/api/categories/",
  metrics: "/api/metrics/",
  records: "/api/records/",
  strata: "/api/strata/",
  geoStrata: "/api/geo-strata/",
  inequities: "/api/inequities/",
  years: "/api/records/years/",
};

function fetchCategories() {
  return fetch(apiURLs.categories).then(response => response.json());
};

function fetchCities() {
  return fetch(apiURLs.cities).then(response => response.json());
};

function fetchCity({ cityId }) {
  return fetch(apiURLs.cities + cityId).then(response => response.json());
};

function fetchMetrics({ available_for_city }) {
  const metricsURL = new URL(apiURLs.metrics, window.location.origin);
  if (available_for_city) metricsURL.searchParams.append("available_for_city", available_for_city);
  return fetch(metricsURL).then(response => response.json());
};

function fetchMetric({ metricId, available_for_city }) {
  const metricsURL = new URL(apiURLs.metrics + metricId, window.location.origin);
  if (available_for_city) metricsURL.searchParams.append("available_for_city", available_for_city);
  return fetch(metricsURL).then(response => response.json());
};

function fetchStrata() {
  return fetch(apiURLs.strata).then(response => response.json());
};

function fetchRecords({ metricId, cityId, year, cities }) {
  const recordsURL = new URL(apiURLs.records, window.location.origin);
  recordsURL.searchParams.append("metric", metricId);
  if (cityId) recordsURL.searchParams.append("city", cityId);
  if (year) recordsURL.searchParams.append("year", year);
  if (cities) recordsURL.searchParams.append("cities", cities.join(","))
  return fetch(recordsURL).then(response => response.json());
};

function fetchGeoStrata() {
  return fetch(apiURLs.geoStrata).then(response => response.json());
}

function fetchInequities() {
  return fetch(apiURLs.inequities).then(response => response.json());
}

function fetchYears() {
  return fetch(apiURLs.years).then(response => response.json());
}

export {
  apiURLs,
  fetchCategories,
  fetchCities,
  fetchCity,
  fetchMetrics,
  fetchMetric,
  fetchStrata,
  fetchRecords,
  fetchGeoStrata,
  fetchInequities,
  fetchYears,
};
