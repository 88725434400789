<template>
  <div class="select-group-item">
    <div class="col w2">
      <div class="field">
        <label :class="{'sr-only': index != 0}">Metric Search</label>
        <v-select
          :options="options"
          :reduce="(option) => option.idn"
          :selectable="(option) => option.type == 'option' && option.available_for_city"
          :filterBy="filterOptions"
          :value="!loading && metric.idn ? metric.idn : null"
          :placeholder="loading ? 'Loading metrics' : 'Choose a metric'"
          :clearable="false"
          @input="selectMetric"
        >
          <template #option="{ label, type }">
            <span :class="{
              'vs-item': type == 'option',
              'vs-item-heading': type == 'heading',
              'vs-item-subheading': type == 'subheading',
            }">
              {{ label }}
            </span>
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No metrics found for <em>{{ search }}</em>.
            </template>
            <em style="opacity: 0.5" v-else>Select a city to load metrics</em>
          </template>
        </v-select>
        <button class="link-button" id="show-modal" @click="$emit('show-modal', index)">Browse by category</button>
      </div>
    </div>

    <div class="col min">
      <div class="field">
        <span v-if="index == 0">
          <label for="id_group_by">Group By</label>
          <div class="tooltip">
            <em class="tooltip-icon">i</em>
            <span class="tooltip-text">{{ groupByTooltip }}</span>
          </div>
        </span>
        <v-select
          :options="strataOptions"
          :clearable="false"
          :value="selectedStrata"
          :selectable="(option) => !option.disabled"
          :disabled="metric.available_strata.length == 0"
          @input="selectStrata"
        ></v-select>
      </div>
    </div>

    <div class="col min">
      <div class="field">
        <span v-if="index == 0">
          <label>Years</label>
          <div v-if="singleCity !== true" class="tooltip">
            <em class="tooltip-icon">i</em>
            <span class="tooltip-text">
              Ability to see 'All Years' is limited to where fewer than 7 cities are selected, and if cities are not
              grouped
              by 'Group Cities'
            </span>
          </div>
        </span>
        <v-select
          @input="selectYear"
          :options="yearOptions"
          :value="selectedYear"
          :selectable="(option) => !option.disabled"
          :clearable="false"
        ></v-select>
      </div>
    </div>

    <div class="col w1" v-if="hasSort">
      <div class="field">
        <span v-if="index == 0">
          <label>Sort by</label>
          <div class="tooltip">
            <em class="tooltip-icon">i</em>
            <span class="tooltip-text">
              Ability to sort by is not available for 'All Years' and if data are grouped by 'Race' or 'Sex'
            </span>
          </div>
        </span>
        <v-select
          :options="sortOptions"
          :value="selectedSort"
          :selectable="(option) => !option.disabled"
          :clearable="false"
          @input="selectSort"
        ></v-select>
      </div>
    </div>

    <div class="select-group-item-remove">
      <button @click="$emit('remove-metric', index)" v-if="index > 0" class="remove-button icon-button is-red">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor"
            d="M7.17259 15.1726L10.2826 12.0626L7.10999 8.89001L9.35609 6.64391L12.5287 9.8165L15.6579 6.68731L17.7604 8.78986L14.6312 11.919L17.8414 15.1292L15.5953 17.3753L12.3851 14.1652L9.27514 17.2751L7.17259 15.1726Z" />
          <circle stroke="currentColor" cx="12.5" cy="12.5" r="11.5" stroke-width="2" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { getMetricOptions, filterOptions } from "../utils";

export default {
  props: [
    "categoriesOptions",
    "metric",
    "strataLabels",
    "index",
    "yearFilter",
    "strataFilter",
    "sortBy",
    "hasSort",
    "disableAllYearsOption",
    "disableStrataSelect",
    "loading",
    "singleCity",
  ],

  computed: {
    options: function () { return getMetricOptions(this.categoriesOptions) },

    strataOptions: function () {
      return [
        { label: "---", value: null },
        ...Object.values(this.strataLabels).map((strata) => ({
          label: strata.label,
          value: strata.slug,
          disabled:
            this.disableStrataSelect ||
            this.metric.available_strata.indexOf(strata.slug) < 0,
        })),
      ];
    },

    sortOptions: function () {
      return [
        { value: "name", label: "City name" },
        { value: "high", label: "High to Low", disabled: !!this.strataFilter || !this.yearFilter },
      ]
    },

    selectedStrata: function () {
      return this.strataOptions.filter(
        (o) => o.value == (this.strataFilter || null)
      )[0];
    },

    selectedStrataConfig: function () {
      return this.strataFilter ? this.strataLabels[this.strataFilter] : { is_cross: false };
    },

    yearOptions: function () {
      return [
        {
          label: "All Years",
          value: null,
          disabled: this.disableAllYearsOption,
        },
        ...this.metric.years.map((y) => ({ label: y, value: y })),
      ];
    },

    selectedYear: function () {
      return this.yearOptions.filter(
        (o) => o.value == (this.yearFilter || null)
      )[0];
    },

    selectedSort() {
      return this.sortOptions.filter(o => o.value == (this.strataFilter ? "name" : this.sortBy));
    },

    groupByTooltip: function () {
      return this.singleCity
        ? "Ability to group data is limited to where data are available."
        : "Ability to group data is limited to where data are available and if fewer than 7 cities are selected";
    },
  },
  methods: {
    filterOptions(option, label, search) { return filterOptions(option, label, search) },

    selectMetric(value) {
      this.$emit("metric-selected", this.index, value);
    },

    selectStrata(option) {
      this.$emit("strata-selected", this.index, option.value);
    },

    selectYear(option) {
      this.$emit("year-selected", this.index, option.value);
    },

    selectSort(option) {
      this.$emit("sort-selected", this.index, option.value);
    },

  },
};
</script>
