<template>
  <div>
    <highcharts :options="chartOptions" v-if="chartOptions"></highcharts>
    <div class="data-source" v-if="!hideDataSource">
      <p v-if="metric.display_source && metric.sources">
        <b class="data-source-title">Data Source</b>
        <span v-for="source in metric.sources" :key="source.label" style="margin-right: 1ch;">
          <a :href="source.url" target="_blank" class="data-source-link" v-if="source.url">{{ source.label }}</a>
          <span v-else>{{ source.label }}</span>
        </span>
      </p>
      <p v-if="metric.calculation_footnote && metric.display_calculation_footnote">
        <b class="data-source-title">Metric Details</b>{{ metric.calculation_footnote }}
      </p>
      <p v-if="footnotes && Object.keys(footnotes.notes).length">
        <b class="data-source-title">Footnotes</b>
        <span v-for="(label, note, index) in footnotes.notes" :key="index">
          <span v-if="index > 0">, </span><a :href="`#${footnotes.prefix}${index + 1}`" class="data-source-link">
            {{ footnotes.prefix }}{{ index + 1 }}
          </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { round } from "../utils";
import { fetchCity } from "../api";
import exportChart from "../chartDownload";

export default {
  props: [
    "metric",
    "records",
    "yearFilter",
    "strataFilter",
    "strataLabels",
    "isCrossClassified",
    "footnotes",
    "title",
    "hideDataSource",
    "minValue",
    "maxValue",
  ],

  data() {
    return { loading: false }
  },

  watch: {
    // When chart parameters change, force it to rerender because heighcharts messes up the series
    records: function () { this.foceRefresh() },
    strataFilter: function () { this.foceRefresh() },
    yearFilter: function () { this.foceRefresh() },
    strataValues: function () { this.foceRefresh() },
  },

  computed: {
    strataValues: function () {
      return this.strataFilter
        ? this.strataLabels[this.strataFilter].values
        : [];
    },

    years: function () {
      return this.yearFilter ? [this.yearFilter] : this.metric.years;
    },

    chartOptions: function () {
      if (!this.metric.idn || this.loading) return false;

      const series = this.strataFilter
        ? this.getStratifiedSeries()
        : this.getSimpleSeries();

      const title = this.title ? this.title : this.getTitle();

      const allValues = Object.values(series)
        .map((s) => s.data)
        .flat();

      const sources = this.metric.display_source && this.metric.sources;
      const sourcesText = sources.length ? sources.map(s => s.label).join(" ") : null;
      const sourceHeight = sources.length ? (parseInt(sourcesText.length / 50) || 1) * 20 : undefined;
      const writeCSV = this.writeCSV;

      return {
        chart: {
          type: this.years.length == 1 ? "bar" : "line",
        },
        title: {
          text: title,
        },
        subtitle: {
          text: this.metric.subtitle,
        },
        xAxis: {
          categories: this.years,
        },
        yAxis: {
          title: {
            text: this.metric.y_axis_units_label,
          },
          min: !isNaN(this.minValue)
            ? this.minValue
            : !isNaN(this.metric.min_y_axis_value)
              ? Math.min(this.metric.min_y_axis_value, ...allValues)
              : undefined,
          max: this.maxValue || (
            this.metric.max_y_axis_value
              ? Math.max(this.metric.max_y_axis_value, ...allValues)
              : undefined),
        },
        tooltip: {
          formatter: function (tooltip) {
            if (!this.series.name) {
              return `${this.point.category}<br/><b>${this.point.y}</b>`;
            }
            return tooltip.defaultFormatter.call(this, tooltip);
          },
        },
        plotOptions: {
          series: {
            connectNulls: true,
          },
        },
        legend: {
          enabled: this.strataFilter || series.length > 1,
        },
        series: series,
        lang: {
          noData: "No data available for this selection.",
        },
        noData: {
          style: {
            fontWeight: "bold",
            fontSize: "15px",
            color: "#303030",
          },
        },
        exporting: {
          sourceWidth: 500,
          chartOptions: sourcesText ? {
            chart: {
              spacingBottom: sourceHeight + 24,
              events: {
                load: function () {
                  this.renderer.text(
                    `<b/>Data Source</b> ${sourcesText}`,
                    12,
                    this.chartHeight - sourceHeight + 12,
                    true,
                  ).css({
                    'font-size': '10px',
                    'width': this.chartWidth - 24,
                  }).add();
                }
              },
            }
          } : {},
          menuItemDefinitions: {
            downloadData: {
              text: "Download Data" + (this.metric.download_allowed ? "" : " (not available)"),
              onclick: this.metric.download_allowed ? writeCSV : null,
            },
          },
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "separator", "downloadData"],
            },
          },
        },
      };
    },
  },

  methods: {
    filterSimpleSeriesRecords() {
      const records = [];
      const metricIsStratified = this.metric.available_strata.length > 0;
      this.records.forEach((record) => {
        const stratified = Object.values(record.strata).filter(
          (s) => s.is_stratified
        );
        const recordHasStrataField = Object.values(record.strata).length > 0;
        if (
          !metricIsStratified ||
          (recordHasStrataField && !stratified.length)
        ) {
          records.push(record)
        }
      });
      return records;
    },

    getSimpleSeries() {
      const yearsMap = Object.fromEntries(this.years.map((y) => [Number(y), null]));
      this.filterSimpleSeriesRecords().forEach((record) => {
        yearsMap[Number(record.date_label)] = round(record.value);
      });
      return [{ name: "", data: Object.values(yearsMap) }];
    },

    filterStratifiedSeriesRecords() {
      const records = [];

      this.records.forEach((record) => {
        const recordStrata = record.strata[this.strataFilter];
        if (recordStrata) {
          const otherStrata = Object.entries(record.strata).filter(
            ([label, srtataObj]) =>
              label != this.strataFilter && srtataObj.is_stratified
          );

          const validSingleStrata = !this.isCrossClassified && !otherStrata.length;
          const isCrossClassified = this.isCrossClassified || this.strataLabels[this.strataFilter].is_cross;
          const validCrossStrata = isCrossClassified && otherStrata.length;

          if (validSingleStrata || validCrossStrata) {
            records.push(record);
          }
        }
      })
      return records;
    },

    getStratifiedSeries() {
      // { "Female": { 2020: 1, 2021: 2 }, "Male": { 2020: null, 2021: 1 } }
      const emptyYearsMap = Object.fromEntries(this.years.map((y) => [y, null]));
      const strataSeries = Object.fromEntries(
        this.strataValues.map((s) => [
          s.value,
          { color: s.color, yearsData: { ...emptyYearsMap } },
        ])
      );

      this.filterStratifiedSeriesRecords().forEach((record) => {
        const year = Number(record.date_label);
        const recordStrata = record.strata[this.strataFilter];
        const strataValue = recordStrata.value;
        if (strataSeries[strataValue] == undefined) {
          strataSeries[strataValue] = { yearsData: { ...emptyYearsMap } };
        }
        strataSeries[strataValue]["yearsData"][year] = round(record.value);
      });

      // [
      //   { name: "Female", data: [1, 2], color: "#000" },
      //   { name: "Male", data: [1.5, null], color: "#666" },
      // ]
      const series = [];
      Object.entries(strataSeries).forEach(([name, config]) => {
        const hasData =
          Object.values(config.yearsData).filter((d) => d != null).length > 0;
        // Show all strata series if cross classified otherwise only if has data
        if (this.isCrossClassified || hasData) {
          series.push({
            name: name,
            color: config.color,
            data: Object.values(config.yearsData),
            marker: { symbol: "circle" },
          });
        }
      });
      return series;
    },

    getTitle() {
      let title = this.metric.label;
      if (this.strataFilter) {
        const label = this.strataLabels[this.strataFilter].label;
        title += ` by ${label}`;
      }
      if (this.yearFilter) {
        title += ` in ${this.yearFilter}`;
      } else if (this.metric.years.length == 1) {
        title += ` in ${this.metric.years[0]}`;
      } else {
        title += ", All Years";
      }
      return title;
    },

    foceRefresh() {
      this.loading = true;
      this.$nextTick(function () {
        this.loading = false;
      })
    },

    // Download

    writeCSV() {
      const records = this.strataFilter
        ? this.filterStratifiedSeriesRecords()
        : this.filterSimpleSeriesRecords()
      const cityId = records[0].city;
      fetchCity({ cityId }).then((city) => {
        const cities = Object.fromEntries([[city.id, city]])
        const title = `${city.label} - ${this.title || this.getTitle()}.csv`
        exportChart(this.metric, cities, this.strataFilter, records, title)
      })
    },
  },

  components: {
    highcharts: Chart,
  },
};
</script>
