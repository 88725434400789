<template>
  <div>
    <div class="select-group-wrap">
      <ul class="select-group-list">
        <li
          v-for="(config, index) in selectedMetrics"
          :key="`${index}-${config.metric.idn}`"
        >
          <metric-select
            :index="index"
            :categoriesOptions="categoriesOptions"
            :strataLabels="strataLabels"
            :metric="config.metric"
            :yearFilter="config.yearFilter"
            :strataFilter="config.strataFilter"
            :loading="loading"
            :singleCity="true"
            @metric-selected="onSelectMetric"
            @strata-selected="onSelectStrata"
            @year-selected="onSelectYear"
            @remove-metric="onRemoveMetric"
            @show-modal="onShowModal"
          ></metric-select>
        </li>
      </ul>

      <div class="select-group-add-wrap">
        <button class="button" @click="addMetric">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4.48673H4.39819V0H7.57466V4.48673H12V7.46018H7.57466V12H4.39819V7.46018H0V4.48673Z"
              fill="currentColor"
            />
          </svg>
          Add Metric
        </button>
      </div>
    </div>

    <main class="page-section" v-if="selectedMetrics[0].metric.idn">
      <ul class="graph-list">
        <li
          :class="{
            'graph-item': !shouldSplitCharts(config),
            'graph-group': shouldSplitCharts(config),
          }"
          v-for="(config, index) in selectedMetrics"
          :key="index"
          :ref="`metric-${index}`"
        >
          <metric-chart
            v-if="config.metric.idn && !shouldSplitCharts(config) && config.records.length"
            :metric="config.metric"
            :records="config.records"
            :yearFilter="config.yearFilter"
            :strataFilter="config.strataFilter"
            :footnotes="footnotesConfig[index]"
            :strataLabels="strataLabels"
          >
          </metric-chart>
          <metric-charts-group
            v-if="config.metric.idn && shouldSplitCharts(config) && config.records.length"
            :metric="config.metric"
            :records="config.records"
            :yearFilter="config.yearFilter"
            :strataFilter="config.strataFilter"
            :strataLabels="strataLabels"
            :footnotes="footnotesConfig[index]"
          >
          </metric-charts-group>
          <div v-if="!config.records.length" class="empty-data">
            <p>No data available for this selection.</p>
          </div>
        </li>
      </ul>

      <div
        class="footnotes"
        v-if="footnotesConfig.filter((c) => Object.keys(c.notes).length).length"
      >
        <h5 class="footnotes-title">Footnotes</h5>
        <div
          v-for="(config, index) in footnotesConfig"
          :key="index"
          class="footnote-group"
        >
          <div
            v-for="(label, note, i) in config.notes"
            :key="i"
            class="footnote"
          >
            <strong :id="`${config.prefix}${i + 1}`"
              >{{ config.prefix }}{{ i + 1 }}</strong
            >
            <p>
              <strong>{{ label }}</strong> {{ note }}
            </p>
          </div>
        </div>
      </div>
    </main>

    <metrics-modal
      :showModal="showModal"
      :categoriesOptions="categoriesOptions"
      :modalSelectIndex="modalSelectIndex"
      :empty="!Object.keys(metrics).length"
      @close-modal="showModal = false"
      @metric-selected="onSelectMetric"
    >
    </metrics-modal>
  </div>
</template>

<script>
import {
  fetchMetrics,
  fetchMetric,
  fetchStrata,
  fetchRecords,
  fetchCategories,
} from "./api";
import { buildFootnotes } from "./utils";

import metricsModal from "./components/metricsModal";
import metricSelect from "./components/metricSelect";
import metricChart from "./components/metricChart";
import metricChartsGroup from "./components/metricChartsGroup";

const emptyMetric = { id: null, available_strata: [], years: [] };
const emptyMetricConfig = {
  metric: { ...emptyMetric },
  strataFilter: null,
  yearFilter: null,
  records: [],
  sources: [],
};

export default {
  name: "cityProfile",

  data() {
    return {
      loading: true,
      metrics: {},
      strataLabels: {},
      selectedMetrics: [{ ...emptyMetricConfig }, { ...emptyMetricConfig }],
      categoriesOptions: [],
      showModal: false,
      modalSelectIndex: null,
    };
  },

  computed: {
    footnotesConfig: function () {
      return buildFootnotes(this.selectedMetrics);
    },
  },

  created() {
    Promise.all([
      fetchMetrics({ available_for_city: this.$cityId }),
      fetchCategories(),
    ]).then(([metrics, categoies]) => {
      this.categoriesOptions = categoies.map((category) => {
        return {
          ...category,
          subcategories: category.subcategories.map((subcategory) => ({
            ...subcategory,
            metrics: metrics.filter((m) => m.subcategory == subcategory.id),
          })),
        };
      });

      this.metrics = Object.fromEntries(
        metrics.map((metric) => [metric.idn, metric])
      );

      const availableMetrics = metrics
        .filter((m) => m.available_for_city)
        .sort((metric, other) => {
          if (metric.default_selected) return -1;
          if (other.default_selected) return 1;
          return 0;
        });

      const selectedMetrics = this.getMetricsConfigFromURL() ||
      // return up to two default selected
        availableMetrics
          .filter(metric => metric.default_selected === true)
          .slice(0, 2)
          .map(metric => ({ ...emptyMetricConfig, ...{ metric } }));
      this.loadInitialMetrics(selectedMetrics);
    });

    fetchStrata().then(
      (strataLabels) =>
        (this.strataLabels = Object.fromEntries(
          strataLabels.map((l) => [l.slug, l])
        ))
    );
  },

  methods: {
    onSelectMetric(index, metricIdn) {
      const metricId = this.metrics[metricIdn].id;

      return fetchMetric({ metricId, available_for_city: this.$cityId }).then(
        (metric) => {
          const metricConfig = { ...emptyMetricConfig, ...{ metric } };
          this.$set(this.selectedMetrics, index, metricConfig);
          this.updateRecords(index);
          this.showModal = false;
          this.scrollToMetric(index);
          this.updateURL();
        }
      );
    },

    onSelectStrata(index, strataFilter) {
      const config = this.selectedMetrics[index];
      this.$set(this.selectedMetrics, index, {
        ...config,
        ...{ strataFilter },
      });

      this.scrollToMetric(index);
      this.updateURL();
    },

    onSelectYear(index, yearFilter) {
      const config = this.selectedMetrics[index];
      this.$set(this.selectedMetrics, index, { ...config, ...{ yearFilter } });
      this.updateRecords(index);

      this.scrollToMetric(index);
      this.updateURL();
    },

    updateAllRecords() {
      for (let i = 0; i < this.selectedMetrics.length; i++) {
        this.updateRecords(i);
      }
    },

    updateRecords(index) {
      const metricConfig = this.selectedMetrics[index];
      const metricId = metricConfig.metric.id;
      const cityId = this.$cityId;
      const year = metricConfig.yearFilter;

      fetchRecords({ metricId, cityId, year }).then((records) => {
        this.$set(this.selectedMetrics, index, {
          ...metricConfig,
          ...{ records },
        });
      });
    },

    loadInitialMetrics(selectedMetrics) {
      const updateMetric = (index, config) => {
        return fetchMetric({
          metricId: config.metric.id,
          available_for_city: this.$cityId,
        }).then((metric) => {
          const metricConfig = { ...config, ...{ metric } };
          this.$set(this.selectedMetrics, index, metricConfig);
          this.updateRecords(index);
          this.updateURL();
        });
      };

      const promises = selectedMetrics
        .filter((config) => config.metric.id)
        .map((config, index) => updateMetric(index, config));

      Promise.all(promises).then(() => (this.loading = false));
    },

    addMetric() {
      const index = this.selectedMetrics.length;
      this.$set(this.selectedMetrics, index, emptyMetricConfig);
      this.updateURL();
    },

    onRemoveMetric(index) {
      this.$delete(this.selectedMetrics, index);
      this.updateURL();
    },

    onShowModal(index) {
      this.modalSelectIndex = index;
      this.showModal = true;
    },

    // Behavior
    shouldSplitCharts(config) {
      // Split cross-classified data into multiple charts
      return !config.yearFilter
        && config.metric.years.length > 1
        && config.strataFilter
        && this.strataLabels[config.strataFilter].is_cross;
    },

    scrollToMetric(index) {
      this.$nextTick(function () {
        const chartEl = this.$refs[`metric-${index}`];
        if (chartEl && chartEl.length)
          chartEl[0].scrollIntoView({ behavior: "smooth" });
      });
    },

    updateURL() {
      const metricIds = this.selectedMetrics.map((i) => i.metric.idn);
      const years = this.selectedMetrics.map((i) => i.yearFilter);
      const strata = this.selectedMetrics.map((i) => i.strataFilter);
      const url = new URL(window.location);

      url.searchParams.set("metrics", metricIds.join(","));
      url.searchParams.set("years", years.join(","));
      url.searchParams.set("groups", strata.join(","));

      history.pushState(
        { title: document.title, url: url.href },
        document.title,
        url.href
      );
    },

    getMetricsConfigFromURL() {
      const url = new URL(window.location);

      const urlMetrics = url.searchParams.get("metrics")
        ? url.searchParams.get("metrics").split(",")
        : [];
      const urlYears = url.searchParams.get("years")
        ? url.searchParams.get("years").split(",")
        : [];
      const urlStrata = url.searchParams.get("groups")
        ? url.searchParams.get("groups").split(",")
        : [];

      if (urlMetrics.length) {
        return urlMetrics.map((metricIdn, index) => {
          const metric = metricIdn && this.metrics[metricIdn];
          const yearFilter = urlYears[index] ? urlYears[index] : null;
          const strataFilter = urlStrata[index] || null;
          return {
            ...emptyMetricConfig,
            metric: metric.available_for_city ? metric : emptyMetric,
            yearFilter,
            strataFilter,
          };
        });
      }

      return false;
    },
  },

  components: {
    "metric-select": metricSelect,
    "metric-chart": metricChart,
    "metrics-modal": metricsModal,
    metricChartsGroup: metricChartsGroup,
  },
};
</script>
