<template>
  <div class="select-group-item">
    <div class="col w1">
      <div class="field">
        <label>Primary City</label>
        <v-select
          :options="primaryCityOptions"
          :reduce="(option) => option.id"
          :value="!loading && mainCityId ? mainCityId : null"
          :clearable="false"
          :placeholder="loading ? 'Loading cities' : 'Choose primary city'"
          @input="selectMainCity"
        >
          <template v-slot:option="option">
            <span :class="{ 'dropdown-highlight': option.state == null }">{{
              option.label
            }}</span>
          </template>
        </v-select>
      </div>
    </div>

    <div class="col w2">
      <div class="field">
        <label>Cities for comparison</label>
        <v-select
          multiple
          :options="comparedOptions"
          :reduce="(option) => option.id"
          :value="!loading && selectedOptions ? selectedOptions : null"
          :clearable="false"
          :selectable="(option) => comparedCitiesIds.length < maxComparedCities || option.id == 'All' || option.id == 'Peer'"
          :placeholder="loading ? 'Loading cities' : 'Choose cities to compare'"
          @input="selectComparedCities"
        >
          <template v-slot:option="option">
            <span :class="{ 'dropdown-highlight': !option.state }">{{
              option.label
            }}</span>
          </template>
        </v-select>
      </div>
    </div>

    <div class="col w1">
      <div class="field">
        <span>
          <label>Group cities by</label>
        </span>
        <v-select :options="geoStrataOptions" :value="selectedGeoStrata" :clearable="false" @input="selectGeoStrata">
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>

function sortCities(a, b) {
  if (a.slug == "us-total") return -1
  if (b.slug == "us-total") return 1
  if (a.label < b.label) return -1
  if (b.label < a.label) return 1
  return 0
}

export default {
  props: [
    "cities",
    "mainCityId",
    "comparedCitiesIds",
    "maxComparedCities",
    "geoStrataLabels",
    "geoStrataFilter",
    "loading",
    "peerCitiesIds",
  ],

  data() {
    return {
      allSelected: false,
      peerSelected: false,
    };
  },

  updated() {
    if (this.comparedCitiesIds.length == this.cities.length - 1) {
      this.allSelected = true;
    }
    else if (this.comparedCitiesIds.length && this.peerCitiesIds.filter(i => this.comparedCitiesIds.includes(i)).length) {
      this.peerSelected = true;
    }
  },

  computed: {
    primaryCityOptions() {
      return this.cities.filter((city) => city.state).sort(sortCities);
    },

    comparedOptions() {
      const all = { id: "All", label: "All Cities", state: null };
      const peer = { id: "Peer", label: "5 Comparison Cities", state: null };
      const sorted = this.cities
        .filter((c) => c.id != this.mainCityId)
        .sort(sortCities);
      return this.allSelected
        ? [all]
        : [all, peer, ...sorted];
    },

    selectedOptions() {
      return this.allSelected ? ["All"] : this.comparedCitiesIds;
    },

    geoStrataOptions() {
      return [
        { value: null, label: "---" },
        ...Object.values(this.geoStrataLabels).map((l) => ({
          value: l.slug,
          label: l.label,
        })),
      ];
    },

    selectedGeoStrata: function () {
      return this.geoStrataOptions.filter(
        (o) => o.value == (this.geoStrataFilter || null)
      )[0];
    },
  },

  methods: {
    selectMainCity(value) {
      this.$emit("main-city-selected", value);
      this.peerSelected = false;
      this.allSelected = false;
    },

    selectComparedCities(value) {
      const allSelected = value.indexOf("All") >= 0;
      this.allSelected = allSelected;
      const peerSelected = value.indexOf("Peer") >= 0;
      this.peerSelected = peerSelected;
      const cityIds = allSelected
        ? this.cities.map((c) => c.id).filter((id) => id != this.mainCityId)
        : peerSelected
          ? this.peerCitiesIds
          : value;
      this.$emit("compared-cities-selected", cityIds);
    },

    selectGeoStrata(option) {
      this.$emit("geo-strata-selected", option.value);
    },
  },
};
</script>
