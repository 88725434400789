<template>
  <div class="charts-group">
    <h3 class="graph-group-title">{{ title }}</h3>

    <ul class="graph-list">
      <li class="graph-item" v-for="(filteredRecords, strataValue) in groupedRecords" :key="strataValue">
        <metric-chart :metric="metric" :records="filteredRecords" :yearFilter="yearFilter"
          :strataFilter="secondaryStrata" :strataLabels="secondaryStrataLabels" :isCrossClassified="true"
          :title="`${metric.label} by ${strataLabel}: ${strataValue}, All Years`" :hideDataSource="true"
          :minValue="minValue" :maxValue="maxValue">
        </metric-chart>
      </li>
    </ul>
    <div class="data-source" v-if="!hideDataSource">
      <p v-if="metric.display_source && metric.sources">
        <b class="data-source-title">Data Source</b>
        <span v-for="source in metric.sources" :key="source.label" style="margin-right: 1ch;">
          <a :href="source.url" target="_blank" class="data-source-link" v-if="source.url">{{ source.label }}</a>
          <span v-else>{{ source.label }}</span>
        </span>
      </p>
      <p v-if="metric.calculation_footnote && metric.display_calculation_footnote">
        <b class="data-source-title">Metric Details</b>{{ metric.calculation_footnote }}
      </p>
      <p v-if="footnotes && Object.keys(footnotes.notes).length">
        <b class="data-source-title">Footnotes</b>
        <span v-for="(label, note, index) in footnotes.notes" :key="index">
          <span v-if="index > 0">, </span><a :href="`#${footnotes.prefix}${index + 1}`" class="data-source-link">
            {{ footnotes.prefix }}{{ index + 1 }}
          </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import metricChart from "./metricChart";

export default {
  props: [
    "metric",
    "records",
    "yearFilter",
    "strataFilter",
    "strataLabels",
    "footnotes",
    "hideDataSource",
  ],

  data() {
    return {
      // { "race": ["Hispanic", "Black"] }
      secondaryStrataLabels: {},
      minValue: undefined,
      maxValue: undefined,
    }
  },

  computed: {
    strataLabel: function () {
      return this.strataFilter && this.strataLabels[this.strataFilter].label
    },

    primaryStrata: function () {
      return this.strataFilter && this.strataFilter.split("_")[1]
    },

    primaryStrataValues: function () {
      return this.primaryStrata
        ? this.strataLabels[this.primaryStrata].values
        : [];
    },

    primaryStrataLabel: function () {
      return this.primaryStrata && this.strataLabels[this.primaryStrata].label
    },

    secondaryStrata: function () {
      return this.strataFilter && this.strataFilter.split("_")[0]
    },

    secondaryStrataValues: function () {
      return this.secondaryStrata
        ? this.strataLabels[this.secondaryStrata].values
        : [];
    },

    title: function () {
      let title = `${this.metric.label} by ${this.strataLabel}`;
      if (this.yearFilter) {
        title += ` in ${this.yearFilter}`;
      } else if (this.metric.years.length == 1) {
        title += ` in ${this.metric.years[0]}`;
      } else {
        title += ", All Years";
      }
      return title;
    },

    groupedRecords() {
      // Split cross-classified records by their primary strata values
      // Each of the primary strata labels will be one chart stratified
      // by the secondary strata
      // { "Female": [], "Male": [] }
      const groupedRecords = Object.fromEntries(
        this.primaryStrataValues.map((s) => [s.value, []])
      );
      const secondaryStrataValues = new Set();
      let minValue;
      let maxValue;

      this.records.forEach((record) => {
        const strataObj = record.strata && record.strata[this.strataFilter];
        const value = strataObj && strataObj.value;  // "Hispanic Female"
        const primaryStrataObj = record.strata && record.strata[this.primaryStrata];
        const primaryStrataValue = primaryStrataObj && primaryStrataObj.value;  // "Female"
        const secondaryStrataObj = record.strata && record.strata[this.secondaryStrata];
        const secondaryStrataValue = secondaryStrataObj && secondaryStrataObj.value;  // "Hispanic"
        if (value) {
          if (groupedRecords[primaryStrataValue] == undefined) groupedRecords[primaryStrataValue] = [];
          groupedRecords[primaryStrataValue].push(record);
          secondaryStrataValues.add(secondaryStrataValue);
          minValue = minValue ? Math.min(record.value, minValue) : record.value;
          maxValue = maxValue ? Math.max(record.value, maxValue) : record.value;
        }
      });

      const labelConfig = this.strataLabels[this.secondaryStrata];
      this.secondaryStrataLabels[this.secondaryStrata] = {
        ...labelConfig,
        values: labelConfig.values.filter(c => secondaryStrataValues.has(c.value)),
      }

      this.minValue = !isNaN(this.metric.min_y_axis_value) ? Math.min(this.metric.min_y_axis_value, minValue) : minValue;
      this.maxValue = !isNaN(this.metric.max_y_axis_value) ? Math.max(this.metric.max_y_axis_value, maxValue) : maxValue;

      return Object.fromEntries(
        Object.entries(groupedRecords).filter(
          ([value, records]) => records.length > 0
        )
      );
    },
  },

  components: {
    metricChart: metricChart,
  },
};
</script>
