<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
  >
    <path
      d="M1.25457 3.16271e-07L7 5.58549L12.7454 1.32083e-06L14 1.19486L7 8L-3.5875e-07 1.19486L1.25457 3.16271e-07Z"
      fill="#1B2A46"
    />
  </svg>
</template>

<script>
export default {}
</script>
