<template>
  <div>
    <highcharts :constructorType="'mapChart'" class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import US from '@highcharts/map-collection/countries/us/us-all.geo.json';
import { fetchCities } from "../api/";

export default {
  data() {
    return { cities: [] }
  },
  computed: {
    chartOptions() {
      return {
        title: { text: undefined },
        exporting: {
          enabled: false,
        },
        chart: {
          map: US
        },
        mapNavigation: {
          enableButtons: false
        },
        legend: {
          enabled: false,
        },
        series: [{
          mapData: US,
          borderColor: '#FFF',
          borderWidth: 2,
          nullColor: '#B7BBC1',
          showInLegend: false
        }, {
          type: 'mapline',
          data: Highcharts.geojson(US, 'mapline'),
          color: '#FFF',
          showInLegend: false,
          enableMouseTracking: false
        },  {
          type: 'mappoint',
          color: "#00608F",
          data: this.cities.filter((city) => city.lat && city.lon ),
          dataLabels: { enabled: false },
          tooltip: {
            headerFormat: "",
            pointFormat: "{point.label}"
          },
          marker: {
            radius: 5,
            lineWidth: 1,
          },
          point: {
            events: {
              click: function () {
                location.href = '/city/' + this.slug;
              }
            }
          }
        }],
      }
    }
  },
  created() {
    fetchCities().then(cities => this.cities = cities);
  },
};
</script>

<style>

</style>