import Vue from "vue";
import vSelect from 'vue-select';
import proj4 from 'proj4';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import offlineExporting from 'highcharts/modules/offline-exporting';
import map from 'highcharts/modules/map';

import cityProfile  from "./cityProfile.vue";
import compareCities  from "./compareCities.vue";
import exploreMetrics from "./exploreMetrics.vue";
import inequities from "./inequities.vue";
import OpenIndicator from "./components/vSelectOpenIndicator";
import homeMap from "./components/homeMap";
import downloadForm from "./components/downloadForm";
import metricModalButton from "./components/metricModalButton.vue";

if (typeof window !== 'undefined') {
  window.proj4 = window.proj4 || proj4;
}

exporting(Highcharts);
exportData(Highcharts);
offlineExporting(Highcharts);
map(Highcharts);

Highcharts.setOptions({
  credits: false,
  colors: [
    "#2382AF",
    "#f5c002",
    "#49AD76",
    "#B71C55",
    "#19D9D9",
    "#501CB8",
  ],
  exporting: {
    fallbackToExportServer: false
  },
  lang: {
    thousandsSep: ",",
  }
})

Vue.use(HighchartsVue)

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement("button", { 'class': 'close-button close-button-small' })
  },
  OpenIndicator,
});

Vue.component('v-select', vSelect)

const cityProfileSelector = "city-profile-app";
const cityProfileElement = document.getElementById(cityProfileSelector);
if (cityProfileElement) {
  Vue.prototype.$cityId = cityProfileElement.dataset["cityId"];
  new Vue(cityProfile).$mount(`#${cityProfileSelector}`);
}

if (document.getElementById("compare-cities-app")) {
  new Vue(compareCities).$mount("#compare-cities-app");
}

if (document.getElementById("explore-metrics-app")) {
  new Vue(exploreMetrics).$mount("#explore-metrics-app");
}

if (document.getElementById("inequities-app")) {
  new Vue(inequities).$mount("#inequities-app");
}

if (document.getElementById("home-map")) {
  new Vue(homeMap).$mount("#home-map");
}

if (document.getElementById("home-metrics-modal")) {
  new Vue(metricModalButton).$mount("#home-metrics-modal");
}

if (document.getElementById("download-form-app")) {
  new Vue(downloadForm).$mount("#download-form-app");
}