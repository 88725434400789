<template>
  <div>
    <div class="select-group-wrap select-group-labeled">
      <span class="select-group-step">1</span>
      <h5 class="select-group-label">Select cities to compare</h5>
      <ul class="select-group-list">
        <li>
          <cities-select
            :cities="Object.values(cities)"
            :mainCityId="mainCityId"
            :comparedCitiesIds="comparedCitiesIds"
            :peerCitiesIds="peerCitiesIds"
            :maxComparedCities="maxComparedCities"
            :geoStrataLabels="geoStrataLabels"
            :geoStrataFilter="geoStrataFilter"
            :loading="loadingCities"
            @main-city-selected="onSelectMainCity"
            @compared-cities-selected="onSelectComparedCities"
            @geo-strata-selected="onSelectGeoStrata"
          ></cities-select>
        </li>
      </ul>
    </div>

    <div class="select-group-wrap select-group-labeled">
      <span class="select-group-step">2</span>
      <h5 class="select-group-label">Select metrics</h5>
      <ul class="select-group-list">
        <li
          v-for="(config, index) in selectedMetrics"
          :key="`${index}-${config.metric.idn}`"
        >
          <metric-select
            :index="index"
            :categoriesOptions="categoriesOptions"
            :strataLabels="strataLabels"
            :metric="config.metric"
            :yearFilter="config.yearFilter"
            :strataFilter="config.strataFilter"
            :sortBy="config.sortBy"
            :hasSort="true"
            :disableAllYearsOption="maxComparedCitiesReached || geoStrataFilter"
            :disableStrataSelect="maxComparedCitiesReached"
            :loading="loadingMetrics"
            @metric-selected="onSelectMetric"
            @strata-selected="onSelectStrata"
            @year-selected="onSelectYear"
            @remove-metric="onRemoveMetric"
            @show-modal="onShowModal"
            @sort-selected="onSelectSort"
          ></metric-select>
        </li>
      </ul>

      <div class="select-group-add-wrap">
        <button class="button" @click="onAddMetric">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4.48673H4.39819V0H7.57466V4.48673H12V7.46018H7.57466V12H4.39819V7.46018H0V4.48673Z"
              fill="currentColor"
            />
          </svg>
          Add Metric
        </button>
      </div>
    </div>

    <main class="page-section" v-if="selectedMetrics[0].metric.idn">
      <ul class="graph-list">
        <li :class="{
          'graph-item': !shouldSplitCharts(config),
          'graph-group': shouldSplitCharts(config),
          }"
          v-for="(config, index) in selectedMetrics"
          :key="index"
          :ref="`metric-${index}`"
        >
          <cities-chart
            v-if="!shouldSplitCharts(config) && config.records.length"
            :metric="config.metric"
            :records="config.records"
            :yearFilter="config.yearFilter"
            :cities="selectedCities"
            :strataFilter="config.strataFilter"
            :strataLabels="strataLabels"
            :geoStrataFilter="geoStrataFilter"
            :sortBy="config.sortBy"
            :showSource="true"
            :footnotes="footnotesConfig[index]"
          >
          </cities-chart>
          <cities-charts-group
            v-if="shouldSplitCharts(config) && config.records.length"
            :metric="config.metric"
            :records="config.records"
            :yearFilter="config.yearFilter"
            :cities="selectedCities"
            :strataFilter="config.strataFilter"
            :geoStrataFilter="geoStrataFilter"
            :strataLabels="strataLabels"
            :footnotes="footnotesConfig[index]"
          >
          </cities-charts-group>
        </li>
      </ul>
      <div class="footnotes" v-if="footnotesConfig.filter((c) => Object.keys(c.notes).length).length">
        <h5 class="footnotes-title">Footnotes</h5>
        <div v-for="(config, index) in footnotesConfig" :key="index" class="footnote-group">
          <div v-for="(labels, note, i) in config.notes" :key="i" class="footnote">
            <strong :id="`${config.prefix}${i + 1}`">{{ config.prefix }}{{ i + 1 }}</strong>
            <p v-for="(cities, label) in labels" :key="label">
              {{ cities }}<br />
              <strong>{{ label }}</strong>{{ note }}<br />
            </p>
          </div>
        </div>
      </div>
    </main>

    <main class="page-section" v-if="!selectedMetrics[0].metric.idn || !mainCityId">
      <p class="empty-state">
        To generate a chart, first select a primary city, then select other cities for comparison, and then select a metric. To add more charts, click '+ ADD METRIC'
      </p>
    </main>

    <metrics-modal
      :showModal="showModal"
      :categoriesOptions="categoriesOptions"
      :modalSelectIndex="modalSelectIndex"
      :empty="!Object.keys(metrics).length"
      @close-modal="showModal = false"
      @metric-selected="onSelectMetric"
    >
    </metrics-modal>
  </div>
</template>

<script>
import {
  fetchMetrics,
  fetchMetric,
  fetchStrata,
  fetchRecords,
  fetchCategories,
  fetchCities,
  fetchGeoStrata,
} from "./api";
import { buildCitiesFootnotes } from "./utils";

import citiesSelect from "./components/citiesSelect";
import metricSelect from "./components/metricSelect";
import metricsModal from "./components/metricsModal";
import citiesChart from "./components/citiesChart";
import citiesChartsGroup from "./components/citiesChartsGroup";

const emptyMetric = { id: null, available_strata: [], years: [] };
const emptyMetricConfig = {
  metric: { ...emptyMetric },
  strataFilter: null,
  yearFilter: null,
  sortBy: "name",
  records: [],
};

const emptyCity = {
  id: null,
  label: null,
};

export default {
  name: "compareCities",

  data() {
    return {
      loadingCities: true,
      loadingMetrics: true,
      cities: {},
      metrics: {},
      strataLabels: {},
      geoStrataLabels: {},
      selectedMetrics: [{ ...emptyMetricConfig }],
      mainCityId: { ...emptyCity },
      comparedCitiesIds: [],
      categoriesOptions: [],
      geoStrataFilter: null,
      showModal: false,
      modalSelectIndex: null,
      maxComparedCities: 6,
      peerCitiesIds: [],
    };
  },

  computed: {
    selectedCities() {
      return [
        this.cities[this.mainCityId],
        ...this.comparedCitiesIds.map((i) => this.cities[i]).sort((a, b) => {
          if (a.label < b.label) return -1
          if (a.label > b.label) return 1
          return 0
        }),
      ];
    },

    maxComparedCitiesReached() {
      return this.comparedCitiesIds.length >= this.maxComparedCities;
    },

    footnotesConfig: function () {
      return buildCitiesFootnotes(this.selectedMetrics, this.cities);
    }
  },

  created() {
    fetchCities().then((cities) => {
      this.cities = Object.fromEntries(cities.map((c) => [c.id, c]));
      this.loadingCities = false;
      if (this.mainCityId > 0) {
        this.peerCitiesIds = this.cities[this.mainCityId]['peer_cities']
      }
    });

    fetchStrata().then(
      (strataLabels) =>
      (this.strataLabels = Object.fromEntries(
        strataLabels.map((l) => [l.slug, l])
      ))
    );

    fetchGeoStrata().then(
      (geoStrataLabels) =>
      (this.geoStrataLabels = Object.fromEntries(
        geoStrataLabels.map((l) => [l.slug, l])
      ))
    );

    const url = new URL(window.location);
    const urlMainCity = url.searchParams.get("city");
    const urlComparedCities = url.searchParams.get("cities")
      ? url.searchParams.get("cities").split(",")
      : [];

    if (urlMainCity) {
      this.mainCityId = Number(urlMainCity);
      this.comparedCitiesIds = urlComparedCities.map((i) => Number(i));
      this.geoStrataFilter = url.searchParams.get("geoStrata");
      this.updateMetrics();
    }
  },

  methods: {
    // Callbacks
    onSelectMainCity(cityId) {
      this.mainCityId = cityId;
      this.comparedCitiesIds = [];
      this.comparedCitiesIds.splice(0);
      this.peerCitiesIds = this.cities[cityId]['peer_cities'];
      this.selectedMetrics = [{...emptyMetricConfig}];
      this.updateURL();
      this.updateMetrics();
    },

    onSelectComparedCities(cityIds) {
      for (let i = 0; i < cityIds.length; i++) {
        this.$set(this.comparedCitiesIds, i, cityIds[i]);
      }
      this.comparedCitiesIds.splice(cityIds.length);

      // force single year filter to all metrics if too many compared cities
      if (this.maxComparedCitiesReached) {
        for (let i = 0; i < this.selectedMetrics.length; i++) {
          const config = this.selectedMetrics[i];
          if (config.metric.idn) {
            const lastYear =
              config.metric.years[config.metric.years.length - 1];
            this.$set(this.selectedMetrics, i, {
              ...config,
              yearFilter: lastYear,
              strataFilter: null,
            });
          }
        }
      }

      this.updateAllRecords();
      this.updateURL();
    },

    onSelectMetric(index, metricIdn) {
      const metricId = this.metrics[metricIdn].id;

      return fetchMetric({
        metricId,
        available_for_city: this.mainCityId,
      }).then((metric) => {
        // force single year filter to metric if too many compared cities
        // or geo strata selected
        const yearFilter =
          this.maxComparedCitiesReached || this.geoStrataFilter
            ? metric.years[metric.years.length - 1]
            : null;

        const metricConfig = {
          ...emptyMetricConfig,
          ...{ metric, yearFilter },
        };

        this.$set(this.selectedMetrics, index, metricConfig);
        this.updateRecords(index);
        this.showModal = false;
        this.scrollToMetric(index);
        this.updateURL();
      });
    },

    onSelectStrata(index, strataFilter) {
      const config = this.selectedMetrics[index];
      // Disable high to low sorting if strata
      const sortBy = strataFilter ? "name" : config.sortBy;
      this.$set(this.selectedMetrics, index, {
        ...config,
        ...{ strataFilter },
        ...{ sortBy },
      });
      this.scrollToMetric(index);
      this.updateURL();
    },

    onSelectYear(index, yearFilter) {
      const config = this.selectedMetrics[index];
      const sortBy = !yearFilter ? "name" : config.sortBy
      this.$set(this.selectedMetrics, index, {
        ...config,
        ...{ yearFilter },
        ...{ sortBy },
      });
      this.updateRecords(index);
      this.scrollToMetric(index);
      this.updateURL();
    },

    onSelectGeoStrata(geoStrata) {
      this.geoStrataFilter = geoStrata;

      // force single year filter to all metrics if cities are grouped by geo strata
      for (let i = 0; i < this.selectedMetrics.length; i++) {
        const config = this.selectedMetrics[i];
        if (config.metric.idn) {
          if (!config.yearFilter) {
            const lastYear = config.metric.years[config.metric.years.length - 1];
            this.$set(this.selectedMetrics, i, {
              ...config,
              yearFilter: lastYear,
            });
          }
        }
      }

      this.updateURL();
    },

    onSelectSort(index, sortBy) {
      const config = this.selectedMetrics[index];
      this.$set(this.selectedMetrics, index, { ...config, ...{ sortBy } });
      this.scrollToMetric(index);
      this.updateURL();
    },

    onAddMetric() {
      const index = this.selectedMetrics.length;
      this.$set(this.selectedMetrics, index, emptyMetricConfig);
      this.updateURL();
    },

    onRemoveMetric(index) {
      this.$delete(this.selectedMetrics, index);
      this.updateURL();
    },

    onShowModal(index) {
      this.modalSelectIndex = index;
      this.showModal = true;
    },

    // Update
    updateMetrics() {
      this.loadingMetrics = true;

      Promise.all([
        fetchMetrics({ available_for_city: this.mainCityId }),
        fetchCategories(),
      ]).then(([metrics, categoies]) => {
        this.categoriesOptions = categoies.map((category) => {
          return {
            ...category,
            subcategories: category.subcategories.map((subcategory) => ({
              ...subcategory,
              metrics: metrics.filter((m) => m.subcategory == subcategory.id),
            })),
          };
        });

        this.metrics = Object.fromEntries(
          metrics.map((metric) => [metric.idn, metric])
        );

        const selectedMetrics = this.getMetricsConfigFromURL() || [
          { ...emptyMetricConfig },
        ];

        const updateMetric = (index, config) => {
          return fetchMetric({
            metricId: config.metric.id,
            available_for_city: this.$cityId,
          }).then((metric) => {
            const metricConfig = { ...config, ...{ metric } };
            this.$set(this.selectedMetrics, index, metricConfig);
            this.updateRecords(index);
            this.updateURL();
          });
        };

        const promises = selectedMetrics
          .filter((config) => config.metric.id)
          .map((config, index) => updateMetric(index, config));

        Promise.all(promises).then(() => (this.loadingMetrics = false));
      });
    },

    updateAllRecords() {
      for (let i = 0; i < this.selectedMetrics.length; i++) {
        this.updateRecords(i);
      }
    },

    updateRecords(index) {
      const metricConfig = this.selectedMetrics[index];
      const metricId = metricConfig.metric.id;
      const year = metricConfig.yearFilter;
      const cities = [this.mainCityId, ...this.comparedCitiesIds];

      // first empty records to force chart to rerender to avoid chart trying to
      // display records from years and cities from previous selection
      this.$set(this.selectedMetrics, index, {
        ...metricConfig,
        records: [],
      });

      if (metricId && cities.length) {
        fetchRecords({ metricId, year, cities }).then((records) => {
          this.$set(this.selectedMetrics, index, {
            ...metricConfig,
            ...{ records },
          });
        });
      }
    },

    // Behavior
    shouldSplitCharts(config) {
      // 1 year not stratified -> 1 bar chart
      // 1 year stratified -> 1 bar chart
      // Multiple years not stratified -> 1 line chart
      // Multiple years stratified -> Multiple line charts
      // 1 year cross-stratified -> Multiple bar charts
      const multipleYearsStratified = !config.yearFilter && config.metric.years.length > 1;
      const crossClassified = config.strataFilter && this.strataLabels[config.strataFilter].is_cross;
      const oneYearCrossClassified = config.yearFilter && crossClassified;
      return config.strataFilter && (multipleYearsStratified || oneYearCrossClassified);
    },

    scrollToMetric(index) {
      this.$nextTick(function () {
        const chartEl = this.$refs[`metric-${index}`];
        if (chartEl.length) chartEl[0].scrollIntoView({ behavior: "smooth" });
      });
    },

    // URL

    updateURL() {
      const metricIds = this.selectedMetrics.map((i) => i.metric.idn);
      const years = this.selectedMetrics.map((i) => i.yearFilter);
      const strata = this.selectedMetrics.map((i) => i.strataFilter);
      const sortBy = this.selectedMetrics.map((i) => i.sortBy);
      const url = new URL(window.location);

      url.searchParams.set("city", this.mainCityId);
      url.searchParams.set("cities", this.comparedCitiesIds.join(","));
      url.searchParams.set("metrics", metricIds.join(","));
      url.searchParams.set("years", years.join(","));
      url.searchParams.set("groups", strata.join(","));
      url.searchParams.set("sort", sortBy.join(","));
      url.searchParams.set("geoStrata", this.geoStrataFilter || "");

      history.pushState(
        { title: document.title, url: url.href },
        document.title,
        url.href
      );
    },

    getMetricsConfigFromURL() {
      const url = new URL(window.location);

      const urlMetrics = url.searchParams.get("metrics")
        ? url.searchParams.get("metrics").split(",")
        : [];
      const urlYears = url.searchParams.get("years")
        ? url.searchParams.get("years").split(",")
        : [];
      const urlStrata = url.searchParams.get("groups")
        ? url.searchParams.get("groups").split(",")
        : [];
      const urlSort = url.searchParams.get("sort")
        ? url.searchParams.get("sort").split(",")
        : [];

      if (urlMetrics.length) {
        return urlMetrics.map((metricIdn, index) => {
          const metric = metricIdn && this.metrics[metricIdn];
          const yearFilter = urlYears[index] ? urlYears[index] : null;
          const strataFilter = urlStrata[index] || null;
          const sortBy = urlSort[index] || null;
          return {
            ...emptyMetricConfig,
            metric: metric.available_for_city ? metric : emptyMetric,
            yearFilter,
            strataFilter,
            sortBy
          };
        });
      }

      return false;
    },
  },

  components: {
    "metric-select": metricSelect,
    "cities-chart": citiesChart,
    "cities-select": citiesSelect,
    "cities-charts-group": citiesChartsGroup,
    "metrics-modal": metricsModal,
  },
};
</script>
