<template>
  <div>
    <div class="select-group-wrap">
      <ul class="select-group-list">
        <li>
          <div class="select-group-item">
            <div class="col w2">
              <div class="field">
                <label>Metric Search</label>
                <v-select
                  :options="metricOptions"
                  :reduce="(option) => option.idn"
                  :value="!loadingMetrics && selectedMetric.idn ? selectedMetric.idn : null"
                  :placeholder="loadingMetrics ? 'Loading metrics' : 'Choose a metric'"
                  :filterBy="filterMetricOptions"
                  :clearable="false"
                  @input="onSelectMetric"
                  :selectable="(option) => option.type == 'option'"
                >
                  <template #option="{ label, type }">
                    <span :class="{
                      'vs-item': type == 'option',
                      'vs-item-heading': type == 'heading',
                      'vs-item-subheading': type == 'subheading',
                    }">
                    {{ label }}
                    </span>
                  </template>
                </v-select>
                <button class="link-button" id="show-modal" @click="onShowModal">Browse by category</button>
              </div>
            </div>
            <div class="col w2">
              <div class="field">
                <label>City</label>
                <v-select
                  :options="cityOptions"
                  :reduce="(option) => option.id"
                  :value="!loadingCities && selectedCity.id ? !loadingCities && selectedCity.id : null"
                  :clearable="false"
                  :placeholder="loadingCities ? 'Loading cities' : 'Choose city'"
                  @input="onSelectCity">
                </v-select>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <main class="page-section" v-if="records.length">
      <ul class="graph-list">
        <!-- All years - no strata -->
        <li class="graph-item" :ref="'metric-0'" v-if="selectedMetric.years.length > 1">
          <metric-chart v-if="records.length" :metric="selectedMetric" :records="records" :yearFilter="null"
            :strataFilter="null" :hideDataSource="true" :minValue="minValue" :maxValue="maxValue">
          </metric-chart>
        </li>
        <!-- Latest year - no strata -->
        <li class="graph-item" :ref="'metric-1'">
          <metric-chart v-if="records.length" :metric="selectedMetric" :records="latestYearRecords"
            :yearFilter="latestYear" :strataFilter="null" :hideDataSource="true" :minValue="minValue"
            :maxValue="maxValue">
          </metric-chart>
        </li>
        <!-- All years - stratified -->
        <li v-for="strata in selectedMetric.years.length > 1 ? simpleStrataKeys : []" :key="strata" class="graph-item"
          :ref="'metric-2'">
          <metric-chart v-if="records.length" :metric="selectedMetric" :records="records" :yearFilter="null"
            :strataFilter="strata" :strataLabels="strataLabels" :hideDataSource="true" :minValue="minValue"
            :maxValue="maxValue">
          </metric-chart>
        </li>
        <!-- Latest year - stratified -->
        <li v-for="strata in simpleStrataKeys" :key="`${strata}-${latestYear}`" class="graph-item" :ref="'metric-3'">
          <metric-chart v-if="records.length" :metric="selectedMetric" :records="latestYearRecords"
            :yearFilter="latestYear" :strataFilter="strata" :strataLabels="strataLabels" :hideDataSource="true"
            :minValue="minValue" :maxValue="maxValue">
          </metric-chart>
        </li>
        <!-- Latest year - cross-classified -->
        <li v-for="strata in crossStrataKeys" :key="`${strata}-${latestYear}`" class="graph-item" :ref="'metric-4'">
          <metric-chart v-if="records.length" :metric="selectedMetric" :records="latestYearRecords"
            :yearFilter="latestYear" :strataFilter="strata" :strataLabels="strataLabels" :hideDataSource="true"
            :minValue="minValue" :maxValue="maxValue">
          </metric-chart>
        </li>
        <!-- All years - cross-classified -->
        <li v-for="strata in  selectedMetric.years.length > 1 ? crossStrataKeys : []" :key="strata" class="graph-group"
          :ref="'metric-5'">
          <metric-charts-group v-if="records.length" :metric="selectedMetric" :records="records" :yearFilter="null"
            :strataFilter="strata" :strataLabels="strataLabels" :hideDataSource="true" :minValue="minValue"
            :maxValue="maxValue">
          </metric-charts-group>
        </li>
      </ul>

      <div v-if="records.length &&
                  selectedMetric.display_source &&
                  selectedMetric.sources
                  " class="footnotes">
        <h5 class="footnotes-title">Data Source</h5>
        <p class="footnote">
          <span v-for="source in selectedMetric.sources" :key="source.label" style="margin-right: 1ch;">
            <a v-if="source.url" :href="source.url" target="_blank" class="data-source-link">{{ source.label }}</a>
            <span v-else>{{ source.label }}</span>
          </span>
        </p>
      </div>

      <div v-if="selectedMetric.calculation_footnote && selectedMetric.display_calculation_footnote" class="footnotes">
        <h5 class="footnotes-title">Metric Details</h5>
        <p class="footnote">{{ selectedMetric.calculation_footnote }}
        </p>
      </div>

      <div class="footnotes" v-if="records.length && Object.keys(footnotesConfig.notes).length">
        <h5 class="footnotes-title">Footnotes</h5>
        <div class="footnote-group">
          <div v-for="(label, note, i) in footnotesConfig.notes" :key="i" class="footnote">
            <p>
              <strong>{{ label }}</strong> {{ note }}
            </p>
          </div>
        </div>
      </div>
    </main>

    <main class="page-section" v-if="records.length <= 0">
      <p v-if="loadingCities || loadingMetrics" class="empty-state">
        Loading...
      </p>
      <div v-else>
        <div v-if="selectedCity.id && selectedMetric.id">
          <p v-if="loadingRecords">Loading...</p>
          <p class="empty-state" v-else>
            No data available for {{ selectedMetric.label }} in
            {{ selectedCity.label }}
          </p>
        </div>
        <p class="empty-state" v-else>
          Select a metric and a city to see the data.
        </p>
      </div>
    </main>

    <metrics-modal :showModal="showModal" :categoriesOptions="categoriesOptions" :empty="!Object.keys(metrics).length"
      @close-modal="showModal = false" @metric-selected="onSelectMetric">
    </metrics-modal>
  </div>
</template>

<script>
import {
  fetchMetrics,
  fetchMetric,
  fetchCities,
  fetchStrata,
  fetchRecords,
  fetchCategories,
} from "./api";
import { buildFootnotes, getMetricOptions, filterOptions } from "./utils";

import metricsModal from "./components/metricsModal";
import metricChart from "./components/metricChart";
import metricChartsGroup from "./components/metricChartsGroup";

const emptyMetric = { idn: null, id: null, available_strata: [], years: [] };

const emptyCity = {
  id: null,
  label: null,
};

export default {
  name: "exploreMetrics",

  data() {
    return {
      loadingMetrics: true,
      loadingCities: true,
      loadingRecords: true,
      metrics: {},
      cities: {},
      strataLabels: {},
      selectedMetric: { ...emptyMetric },
      selectedCity: { ...emptyCity },
      records: [],
      categoriesOptions: [],
      showModal: false,
      minValue: undefined,
      maxValue: undefined,
    };
  },

  computed: {
    metricOptions: function () { return getMetricOptions(this.categoriesOptions) },

    cityOptions: function () {
      return Object.values(this.cities)
        .filter((city) => city.state)
        .sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
    },

    footnotesConfig: function () {
      return buildFootnotes([{ records: this.records }])[0];
    },

    latestYear: function () {
      return (
        this.selectedMetric.years.length > 0 &&
        this.selectedMetric.years[this.selectedMetric.years.length - 1]
      );
    },

    latestYearRecords: function () {
      const latestYear = this.latestYear;
      return (
        latestYear &&
        this.records.filter((record) => record.date_label == latestYear)
      );
    },

    simpleStrataKeys: function () {
      return this.selectedMetric.available_strata.filter(s => !this.strataLabels[s].is_cross)
    },

    crossStrataKeys: function () {
      return this.selectedMetric.available_strata.filter(s => this.strataLabels[s].is_cross)
    },
  },

  created() {
    Promise.all([fetchMetrics({}), fetchCategories(), fetchCities()]).then(
      ([metrics, categoies, cities]) => {
        this.categoriesOptions = categoies.map((category) => {
          return {
            ...category,
            subcategories: category.subcategories.map((subcategory) => ({
              ...subcategory,
              metrics: metrics.filter((m) => m.subcategory == subcategory.id),
            })),
          };
        });

        this.metrics = Object.fromEntries(
          metrics.map((metric) => [metric.idn, metric])
        );

        const availableMetrics = metrics.sort((metric, other) => {
          if (metric.default_selected) return -1;
          if (other.default_selected) return 1;
          return 0;
        });

        this.loadingMetrics = false;
        this.loadingCities = false;

        this.cities = Object.fromEntries(cities.map((c) => [c.id, c]));

        const url = new URL(window.location);
        const urlCity = url.searchParams.get("city");
        const cityId = urlCity ? Number(urlCity) : null;
        if (cityId) this.selectedCity = this.cities[cityId];

        const urlMetric = url.searchParams.get("metric");
        const selectedMetric = urlMetric
          ? this.metrics[urlMetric]
          : availableMetrics.find(metric => metric.default_selected === true);
        const metricId = selectedMetric ? selectedMetric.id : availableMetrics[0].id;
        this.updateSelectedMetric(metricId);
      }
    );

    fetchStrata().then(
      (strataLabels) =>
      (this.strataLabels = Object.fromEntries(
        strataLabels.map((l) => [l.slug, l])
      ))
    );
  },

  methods: {
    onSelectMetric(metricIdn) {
      const metric = this.metrics[metricIdn];
      this.showModal = false;
      this.loadingRecords = true;
      this.updateSelectedMetric(metric.id);
    },

    onSelectCity(cityId) {
      this.selectedCity = this.cities[cityId];
      this.loadingRecords = true;
      fetchMetrics({ available_for_city: cityId }).then((metrics) => {
        this.metrics = Object.fromEntries(
          metrics.map((metric) => [metric.idn, metric])
        );
        const metricId = this.metrics[this.selectedMetric.idn].id;
        this.updateSelectedMetric(metricId);
      });
    },

    updateSelectedMetric(metricId) {
      fetchMetric({ metricId, available_for_city: this.selectedCity.id }).then(
        (metric) => {
          this.selectedMetric = metric;
          if (this.selectedCity.id) this.updateRecords();
          this.updateURL();
        }
      );
    },

    updateRecords() {
      const metricId = this.selectedMetric.id;
      const cityId = this.selectedCity.id;
      const metricMin = this.selectedMetric.min_y_axis_value;
      const metricMax = this.selectedMetric.max_y_axis_value;
      if (metricId && cityId) {
        fetchRecords({ metricId, cityId }).then((records) => {
          const allValues = records.map(r => r.value);
          this.loadingRecords = false;
          this.minValue = !isNaN(metricMin)
            ? Math.min(metricMin, Math.min(...allValues))
            : undefined;
          this.maxValue = metricMax
            ? Math.max(metricMax, Math.max(...allValues))
            : undefined;
          this.records = records;
          this.records.splice(records.length);
        });
      }
    },

    onShowModal() {
      this.showModal = true;
    },

    updateURL() {
      const metricIdn = this.selectedMetric.idn;
      const cityId = this.selectedCity.id;
      const url = new URL(window.location);

      if (metricIdn) url.searchParams.set("metric", metricIdn);
      if (cityId) url.searchParams.set("city", cityId);

      history.pushState(
        { title: document.title, url: url.href },
        document.title,
        url.href
      );
    },

    filterMetricOptions(option, label, search) { return filterOptions(option, label, search) },
  },

  components: {
    "metric-chart": metricChart,
    "metrics-modal": metricsModal,
    metricChartsGroup: metricChartsGroup,
  },
};
</script>
