function isNumber(value) {
  return !isNaN(value) && value != null;
}

function round(value) {
  if (value == null) return value
  return Math.round(value * 100) / 100;
}

function buildFootnotes(metricsConfig) {
  // [
  //   { originalIndex: 0, records: [...], hasNotes: false },
  //   { originalIndex: 1, records: [...], hasNotes: true },
  // ]
  const selectedNotes = metricsConfig.map((config, index) => ({
    originalIndex: index,
    records: config.records,
    hasNotes:
      config.records.filter(
        (record) =>
          !!record.date_label_proxy_footnote ||
          !!record.location_proxy_footnote
      ).length > 0,
  }));
  const filteredNotes = selectedNotes.filter((config) => config.hasNotes);
  return selectedNotes.map((config, index) => {
    const newIndex = filteredNotes
      .map((config) => config.originalIndex)
      .indexOf(index);
    const letter = (newIndex + 10).toString(36).toUpperCase(); // 0 = A, 1 = B...
    const allNotes = {};
    if (config.hasNotes) {
      config.records.forEach((record) => {
        if (record.date_label_proxy_footnote) {
          const currentLabel = allNotes[record.date_label_proxy_footnote];
          const date = `${record.date_label}`;
          if (!currentLabel) {
            allNotes[record.date_label_proxy_footnote] = date;
          } else if (currentLabel.indexOf(date) < 0) {
            allNotes[record.date_label_proxy_footnote] += `, ${date}`;
          }
        }
        if (record.location_proxy_footnote) {
          allNotes[record.location_proxy_footnote] = "Location";
        }
      });
    }
    return { notes: allNotes, prefix: letter };
  });
  // [
  //   {
  //     notes: {"Records from 2019":  "2019, 2020", "County data.": "Location" }],
  //     prefix: "A",
  //   },  # origialIndex = 1
  // ]
}

function buildCitiesFootnotes(metricsConfig, cities) {
  let lettersIndex = 0;
  const metricsNotes = metricsConfig.map((config, index) => {
    const records = config.records;
    // { "Records from 2020": { "New York, NY": Set[ 2020, 2021 ], "Chicago, IL": Set[ 2020, 2021 ] } }
    const dateNotes = {};
    // { "County data.":  Set[ "New York, NY", "Chicago, IL" ] }
    const locationNotes = {};
    records.forEach(record => {
      const city = cities[record.city].label;
      if (record.date_label_proxy_footnote) {
        dateNotes[record.date_label_proxy_footnote] = dateNotes[record.date_label_proxy_footnote] || {};
        dateNotes[record.date_label_proxy_footnote][city] = dateNotes[record.date_label_proxy_footnote][city] || new Set();
        dateNotes[record.date_label_proxy_footnote][city].add(record.date_label)
      }
      if (record.location_proxy_footnote) {
        locationNotes[record.location_proxy_footnote] = locationNotes[record.location_proxy_footnote] || new Set();
        locationNotes[record.location_proxy_footnote].add(city);
      }
    });

    // { "Records from 2020": { "2020, 2021": "New York, NY, Chicago, IL" } }
    const groupedDateNotes = Object.fromEntries(
      Object.entries(dateNotes).map(([note, citiesDates]) => {
        const grouped = {};
        Object.entries(citiesDates).forEach(([city, dates]) => {
          const datesStr = [...dates].sort().join(", ");
          grouped[datesStr] = grouped[datesStr] || new Set();
          grouped[datesStr].add(city);
        })
        const groupedStr = Object.fromEntries(Object.entries((grouped)).map(
          ([dates, cities]) => [dates, [...cities].sort().join(", ")]
        ))
        return [note, groupedStr]
      })
    );
    // { "County data.":  { "Location": "New York, NY, Chicago, IL" } }
    const groupedLocationNotes = Object.fromEntries(Object.entries(locationNotes).map(
      ([note, cities]) => [note, { "Location": [...cities].sort().join(", ") }]
    ));

    const allNotes = { ...groupedDateNotes, ...groupedLocationNotes }

    let letter;
    if (Object.keys(allNotes).length) {
      letter = (lettersIndex + 10).toString(36).toUpperCase(); // 0 = A, 1 = B...
      lettersIndex += 1;
    }

    return {
      prefix: letter,
      notes: allNotes,
    }
  });

  return metricsNotes;
  // [
  //   {
  //     prefix: "A",
  //     notes: {
  //      "Records from 2020": ["New York, NY, Chicago, IL", "2020, 2021"] },
  //      "County data.": ["New York, NY", "Location"] },
  //   },
  // ]
}

function getMetricOptions(categories) {
  const options = [];
  categories.forEach((category) => {
    options.push({
      label: category.label,
      idn: null,
      type: "heading",
      key: category.id,
      metrics: category.subcategories.map((subcategory) => subcategory.metrics.map((metric) => metric.label)).flat()
    });
    category.subcategories.forEach((subcategory) => {
      options.push({
        label: subcategory.label,
        categoryLabel: category.label,
        idn: null,
        type: "subheading",
        key: subcategory.id,
        metrics: subcategory.metrics.map((metric) => metric.label)
      });
      subcategory.metrics.forEach((metric) => {
        options.push({
          ...metric,
          categoryLabel: category.label,
          subcategoryLabel: subcategory.label,
          type: "option",
          key: metric.id,
        });
      });
    });
  });
  return options;
}

function filterOptions(option, label, search) {
  let escapedSequence = search.toLowerCase().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  let pattern = escapedSequence.split('').map(char => `${char}.{0,4}`).join('');
  const regex = new RegExp(pattern);
  if (option.type == "option") {
    return regex.test(label.toLowerCase())
  } else {
    return option.metrics.filter((metric) => regex.test(metric.toLowerCase())).length
  }
}

export {
  isNumber,
  round,
  buildFootnotes,
  buildCitiesFootnotes,
  getMetricOptions,
  filterOptions,
}
