<template>
  <div>
    <ul class="select-group-list">
      <li>
        <div class="field">
          <label>Metric</label>
          <v-select
            :options="metricOptions"
            :reduce="(option) => option.id"
            :placeholder="loading ? 'Loading metrics' : 'Choose a metric'"
            :filterBy="filterMetricOptions"
            :clearable="false"
            @input="value => selectedMetric = value"
            :selectable="(option) => option.type == 'option'"
          >
            <template #option="{ label, type, emptyOption }">
              <span :class="{
                'vs-item': type == 'option',
                'vs-item-heading': type == 'heading',
                'vs-item-subheading': type == 'subheading',
                'ml-0': emptyOption,
              }">
                {{ label }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="field">
          <label>City</label>
          <v-select
            :options="cityOptions"
            :reduce="(option) => option.id"
            :placeholder="loading ? 'Loading cities' : 'Choose city'"
            :clearable="false"
            @input="value => selectedCity = value"
          ></v-select>
        </div>
        <div class="field">
          <label>Year</label>
          <v-select
            :options="years"
            :reduce="(option) => option.id"
            :placeholder="loading ? 'Loading years' : 'Choose year'"
            :clearable="false"
            @input="value => selectedYear = value"
          ></v-select>
        </div>
      </li>
    </ul>
    <input type="hidden" name="metric" :value="selectedMetric" />
    <input type="hidden" name="city" :value="selectedCity" />
    <input type="hidden" name="year" :value="selectedYear" />
    <input type="submit" value="Download" class="button large stone mt-2" @click="formSubmitted" />
  </div>
</template>

<script>
import { fetchCities, fetchMetrics, fetchCategories, fetchYears } from "../api/";
import { getMetricOptions, filterOptions } from "../utils";

export default {
  data() {
    return {
      loading: true,
      cities: [],
      categoriesOptions: [],
      years: [],
      selectedMetric: null,
      selectedCity: null,
      selectedYear: null,
    }
  },

  computed: {
    metricOptions: function () {
      return [
        { id: null, type: "option", label: "All metrics", emptyOption: true },
        ...getMetricOptions(this.categoriesOptions)
      ]
    },
    cityOptions: function () {
      return [
        { id: null, label: "All cities" },
        ...Object.values(this.cities)
          .filter((city) => city.state)
          .sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)]
    },
  },

  methods: {
    filterMetricOptions(option, label, search) { return filterOptions(option, label, search) },
    formSubmitted() {
      // Hide errors from previous form submission
      document.querySelectorAll(".form-errors").forEach(function (el) {
        el.remove();
      })
    },
  },

  created() {
    Promise.all([fetchMetrics({}), fetchCategories(), fetchCities(), fetchYears()]).then(
      ([metrics, categoies, cities, years]) => {
        this.years = [
          { id: null, label: "All years" },
          ...years.map(year => ({ id: year, label: year }))
        ];

        this.categoriesOptions = categoies.map((category) => {
          return {
            ...category,
            subcategories: category.subcategories.map((subcategory) => {
              return {
                ...subcategory,
                metrics: metrics.filter((m) => m.subcategory == subcategory.id && m.download_allowed),
              }
            }).filter(s => s.metrics.length),
          };
        });

        this.metrics = Object.fromEntries(
          metrics.map((metric) => [metric.idn, metric])
        );
        this.cities = Object.fromEntries(cities.map((c) => [c.id, c]));
        this.loading = false;
      }
    );
  },
}
</script>
