// Set and retrieve objects from localstorage
Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
  return JSON.parse(this.getItem(key));
};

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

// toggle visiblilty utility
const toggleThisId = document.querySelectorAll("[data-toggle-id]");

if (toggleThisId) {
  toggleThisId.forEach(function (e) {
    const targetId = e.getAttribute("data-toggle-id");
    const matchingElm = document.getElementById(targetId);

    function toggleThis(e) {
      matchingElm.classList.toggle("is-expanded");
      e.currentTarget.classList.toggle("is-expanded");
      console.log("this happened, right?");
      e.preventDefault();
    }

    e.addEventListener("click", toggleThis);
  });
}

function setupModal(modalId) {
  const matchingModal = document.getElementById(modalId);
  const matchingModalScrollWrap = matchingModal.querySelector(".scroll-wrap");

  const closeButtons = matchingModal.querySelectorAll("[data-close-button]");

  closeButtons.forEach(function (e) {
    e.onclick = function () {
      matchingModal.close();
      if (matchingModalScrollWrap) enableBodyScroll(matchingModalScrollWrap);
    };
  });
}

function openModal(modalId) {
  const matchingModal = document.getElementById(modalId);
  const matchingModalScrollWrap = matchingModal.querySelector(".scroll-wrap");

  matchingModal.showModal();
  disableBodyScroll(matchingModalScrollWrap);
}

// dialogs
const modalLink = document.querySelectorAll("[data-open-modal]");

if (modalLink) {
  modalLink.forEach(function (link) {
    const modalId = link.getAttribute("data-open-modal");
    setupModal(modalId);

    link.addEventListener("click", (e) => {
      e.preventDefault();
      openModal(modalId);
    });
  });
}

// Cities modal
const cityList = document.getElementById("city-list");
fetch("/api/cities/")
  .then((response) => response.json())
  .then((cities) => {
    cities.forEach((city) => {
      if (city.state) {
        const item = document.createElement("li");
        const link = document.createElement("a");
        link.href = `/city/${city.slug}/`;
        link.innerHTML = city.label;
        item.appendChild(link);
        cityList.appendChild(item);
      }
    });
  });

// Share modal
const shareModal = document.getElementById("share");
const shareButton = document.getElementById("share-button");
const urlInput = document.getElementById("share-url");
const copyButton = document.getElementById("copy-share-url");
const twitterLink = document.getElementById("twitter-share");
const facebookLink = document.getElementById("facebook-share");
const linkedinLink = document.getElementById("linkedin-share");

function setShareURL() {
  urlInput.value = window.location;

  const twitterURL = new URL("http://twitter.com/share");
  twitterURL.searchParams.set("text", document.title);
  twitterURL.searchParams.set("url", window.location);
  twitterLink.href = twitterURL;

  const facebookURL = new URL("http://www.facebook.com/sharer.php");
  facebookURL.searchParams.set("u", window.location);
  facebookLink.href = facebookURL;

  const linkedInURL = new URL(
    "https://www.linkedin.com/sharing/share-offsite/"
  );
  linkedInURL.searchParams.set("url", window.location);
  linkedinLink.href = linkedInURL;
}
setShareURL();

if (shareButton && shareModal) {
  shareButton.addEventListener("click", setShareURL);
}

function copyShareLink() {
  urlInput.select();
  urlInput.setSelectionRange(0, 99999);
  document.execCommand("copy");
}
copyButton.addEventListener("click", copyShareLink);

function getNumberOfDays(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Single day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculate the difference between start and end dates
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(diffTime / oneDay);

  return diffDays;
}

const userSurveyModal = document.getElementById("user-survey");
const [takenSurveyUrl, surveyDate, takenSurvey] = (() => {
  const takenSurveyObj = window.localStorage.getObject("surveyTaken");
  return [
    takenSurveyObj?.url || null,
    takenSurveyObj?.date || null,
    takenSurveyObj?.taken,
  ];
})();

/*
  Only show the survey popup if:
  1. There is a link to the survey
  2. They have not interacted with the survey popup
  3. It's been more than 2 days since they dismissed the popup withouth taking the survey
  4. The last survey they took is not the current survey available
*/

const userSurvey = document.getElementById("userSurvey");
const currentSurvey = userSurvey && JSON.parse(userSurvey.textContent);
const currentSurveyURL = currentSurvey && currentSurvey.url;

if (
  currentSurveyURL &&
  (!surveyDate ||
    (!takenSurvey && getNumberOfDays(surveyDate, new Date()) >= 2) ||
    takenSurveyUrl !== currentSurveyURL)
) {
  window.addEventListener("load", function () {
    setupModal("user-survey");

    const closeButtons = userSurveyModal.querySelectorAll(
      "[data-close-button]"
    );
    closeButtons.forEach(function (e) {
      userSurveyModal.close();
    });

    document
      .getElementById("take-survey-button")
      .addEventListener("click", () => {
        window.localStorage.setObject("surveyTaken", {
          url: currentSurveyURL.trim(), // Account for accidental whitespace
          date: new Date(),
          taken: true,
        });
      });

    document
      .getElementById("dismiss-survey-button")
      .addEventListener("click", () => {
        window.localStorage.setObject("surveyTaken", {
          url: currentSurveyURL.trim(), // Account for accidental whitespace
          date: new Date(),
          taken: false,
        });
      });

    // Show the popup after 5 seconds
    setTimeout(() => {
      userSurveyModal.show();
    }, 5000);
  });
}
