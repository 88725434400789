const createCsvStringifier = require("csv-writer").createObjectCsvStringifier;

export default function exportChart(
  metric,
  cities,
  strataFilter,
  records,
  title
) {
  const fieldnames_1 = [
    "metric_item_label",
    "geo_label_citystate",
    "date_label",
    "value",
    "metric_source_label_fn",
  ];
  const fieldnames_2 = [
    "metric_item_label_subtitle",
    "metric_cat_item_yaxis_label",
    "geo_label_proxy_or_real",
    "geo_label_proxy_footnote",
    "geo_fips_desc",
    "date_label_proxy_or_real",
    "date_label_proxy_footnote",
    "value_95_ci_low",
    "value_95_ci_high",
  ];
  const strata_fields = [];
  if (strataFilter) {
    metric.available_strata.forEach((i) =>
      strata_fields.push(`strata_${i}_label`)
    );
  }
  const fields = [
    ...fieldnames_1,
    ...strata_fields,
    ...fieldnames_2,
  ].map((i) => ({ id: i, title: i }));

  const sources = [];
  if (metric.sources && metric.display_source) {
    metric.sources.forEach(source => {
      const source_text = source.label + (source.url ? ` [${source.url}]` : "");
      sources.push(source_text);
    })
  }
  const metricData = {
    metric_item_label: metric.label,
    metric_item_label_subtitle: metric.subtitle,
    metric_cat_item_yaxis_label: metric.y_axis_units_label,
    metric_source_label_fn: sources.join(" "),
  };
  const values = [];

  records.forEach((record) => {
    const city = cities[record.city];
    const recordData = {
      geo_label_citystate: city.label,
      value: record.value,
      date_label: record.date_label,
      geo_label_proxy_or_real: record.location_is_proxy ? "proxy" : "real",
      geo_label_proxy_footnote: record.location_proxy_footnote,
      geo_fips_desc: record.fips_description,
      date_label_proxy_or_real: record.date_label_is_proxy ? "proxy" : "real",
      date_label_proxy_footnote: record.date_label_proxy_footnote,
      value_95_ci_low: record.ci_95_low,
      value_95_ci_high: record.ci_95_high,
    };

    if (strataFilter) {
      Object.entries(record.strata).forEach(([slug, strata]) => {
        recordData[`strata_${slug}_label`] = strata.value;
      });
    }
    values.push({ ...metricData, ...recordData });
  });

  const csvStringifier = createCsvStringifier({
    header: fields,
  });
  const text =
    csvStringifier.getHeaderString() + csvStringifier.stringifyRecords(values);

  const blob = new Blob([text], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", title);
  a.click();
};
